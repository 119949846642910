.hm-chat p {
	margin-top: 0;
	margin-bottom: 1rem;
}

.hm-chat ul {
	margin-top: 0;
	margin-bottom: 1rem;
}

.hm-chat a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}

.hm-chat a:hover {
	color: #0056b3;
	text-decoration: underline;
}

.hm-chat img {
	vertical-align: middle;
	border-style: none;
}

.hm-chat button {
	border-radius: 0;
}

.hm-chat button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

.hm-chat button,
.hm-chat input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

.hm-chat button,
.hm-chat input {
	overflow: visible;
}

.hm-chat button {
	text-transform: none;
}

.hm-chat button {
	-webkit-appearance: button;
}

.hm-chat button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

.hm-chat .btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.hm-chat .btn {
		transition: none;
	}
}

.hm-chat .btn:hover {
	color: #212529;
	text-decoration: none;
}

.hm-chat .btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.hm-chat .btn:disabled {
	opacity: 0.65;
}

.hm-chat .btn-primary {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.hm-chat .btn-primary:hover {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
}

.hm-chat .btn-primary:focus {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
	box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.hm-chat .btn-primary:disabled {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.hm-chat .card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

.hm-chat .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
}

.hm-chat .card-title {
	margin-bottom: 0.75rem;
}

.hm-chat .card-text:last-child {
	margin-bottom: 0;
}

.hm-chat .card-img {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 100%;
}

.hm-chat .card-img {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.hm-chat .card-img {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.hm-chat .list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

@media print {
	.hm-chat img {
		page-break-inside: avoid;
	}

	.hm-chat p {
		orphans: 3;
		widows: 3;
	}
}


/*! CSS Used from: https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons */

.hm-chat .chat-box-toggle .material-icons {
	font-weight: bold;
	font-size: 36px;
}

.hm-chat .material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	line-height: 0.5;
}


.hm-chat .card {
	font-weight: 400;
}

.hm-chat p {
	margin-top: 0;
	margin-bottom: 1rem;
}

.hm-chat ul {
	margin-bottom: 1rem;
}

.hm-chat ul {
	margin-top: 0;
}

.hm-chat a {
	color: #0056b3;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

.hm-chat a:hover {
	text-decoration: none;
}

.hm-chat img {
	vertical-align: middle;
	border-style: none;
}

.hm-chat button {
	border-radius: 0;
}

.hm-chat button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

.hm-chat button,
.hm-chat input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

.hm-chat button,
.hm-chat input {
	overflow: visible;
}

.hm-chat button {
	text-transform: none;
}

.hm-chat button {
	-webkit-appearance: button;
}

.hm-chat button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

.hm-chat .btn {
	display: inline-block;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.46875rem 1rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.125rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hm-chat .btn:focus,
.hm-chat .btn:hover {
	text-decoration: none;
}

.hm-chat .btn:focus {
	outline: 0;
	box-shadow: none;
}

.hm-chat .btn:disabled {
	opacity: 0.65;
	box-shadow: none;
}

.hm-chat .btn-primary {
	color: #fff;
	background-color: #4578bc;
	border-color: #4578bc;
	box-shadow: none;
}

.hm-chat .btn-primary:hover {
	color: #fff;
	background-color: #2a5ea3;
	border-color: #2a5ea3;
}

.hm-chat .btn-primary:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(0, 150, 136, 0.5);
}

.hm-chat .btn-primary:disabled {
	color: #fff;
	background-color: #2a5ea3;
	border-color: #2a5ea3;
}

.hm-chat .card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 0.125rem;
}

.hm-chat .card-body {
	flex: 1 1 auto;
	padding: 1.25rem;
}

.hm-chat .card-title {
	margin-bottom: 0.75rem;
}

.hm-chat .card-text:last-child {
	margin-bottom: 0;
}

.hm-chat .card-img {
	width: 100%;
	border-radius: calc(0.125rem - 1px);
}

.hm-chat .list-group {
	padding-left: 0;
	margin-bottom: 0;
}

@media print {
	.hm-chat img {
		page-break-inside: avoid;
	}

	.hm-chat p {
		orphans: 3;
		widows: 3;
	}
}

.hm-chat body {
	font-weight: 400;
}

.hm-chat a:focus,
.hm-chat button:focus {
	outline: none;
}

.hm-chat .btn {
	position: relative;
	margin-bottom: 0.3125rem;
	font-size: 0.875rem;
	text-decoration: none;
	letter-spacing: 0;
	cursor: pointer;
	border: 0;
	outline: 0;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	will-change: box-shadow, transform;
	color: rgba(0, 0, 0, 0.87);
	background-color: transparent;
	border-color: #ccc;
}

.hm-chat .btn:active,
.hm-chat .btn:focus,
.hm-chat .btn:hover {
	color: rgba(0, 0, 0, 0.87);
	background-color: hsla(0, 0%, 60%, 0.2);
	border-color: hsla(0, 0%, 60%, 0.2);
}

.hm-chat .btn:active:focus,
.hm-chat .btn:active:hover {
	color: rgba(0, 0, 0, 0.87);
	background-color: hsla(0, 0%, 60%, 0.4);
	border-color: hsla(0, 0%, 60%, 0.4);
}

.hm-chat .btn:disabled:focus,
.hm-chat .btn:disabled:hover {
	background-color: transparent;
	border-color: #ccc;
}

.hm-chat .btn.btn-primary {
	color: #034a94;
	background-color: transparent;
	border-color: #ccc;
	margin-bottom: 0px;
	font-weight: bold;
}

.hm-chat .btn.btn-primary:active,
.hm-chat .btn.btn-primary:focus,
.hm-chat .btn.btn-primary:hover {
	background-color: hsla(0, 0%, 60%, 0.2);
	border-color: hsla(0, 0%, 60%, 0.2);
	text-decoration: underline;
}

.hm-chat .btn.btn-primary:active:focus,
.hm-chat .btn.btn-primary:active:hover {
	color: #009688;
	background-color: hsla(0, 0%, 60%, 0.4);
	border-color: hsla(0, 0%, 60%, 0.4);
}

.hm-chat .btn.btn-primary:disabled:focus,
.hm-chat .btn.btn-primary:disabled:hover {
	background-color: transparent;
	border-color: #ccc;
}

.hm-chat .btn.btn-raised:focus,
.hm-chat .btn.btn-raised:hover {
	z-index: 1;
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.hm-chat .btn.btn-raised:active {
	z-index: 1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
}

.hm-chat .btn.btn-raised:focus {
	outline: 0;
}

.hm-chat .btn.btn-raised {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.hm-chat .btn.btn-raised:disabled {
	box-shadow: none;
}

.hm-chat .btn .material-icons {
	position: relative;
	display: inline-block;
	top: 0.25em;
	margin-top: -1em;
	margin-bottom: -1em;
	font-size: 1.5em;
	vertical-align: middle;
}

.hm-chat .btn:disabled {
	color: rgba(0, 0, 0, 0.26);
}

.hm-chat .btn:disabled,
.hm-chat .btn:disabled:focus,
.hm-chat .btn:disabled:hover {
	background: transparent;
}

.hm-chat form {
	margin-bottom: 1.125rem;
}

.hm-chat input::placeholder {
	line-height: 1;
}

.hm-chat input::placeholder {
	font-size: 1rem;
}

.hm-chat .list-group {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 0.5rem 0;
}

.hm-chat .card {
	border: 0;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}


/*! CSS Used fontfaces */
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}
