.hm-chat #center-text {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.hm-chat #chat-circle {
	display: none;
	position: fixed;
	bottom: 70px;
	right: 10px;
	background: #4578bc;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	color: #fff;
	padding: 8px;
	cursor: pointer;
	z-index: 999;
}

.hm-chat .btn#my-btn {
	background: white;
	padding-top: 13px;
	padding-bottom: 12px;
	border-radius: 45px;
	padding-right: 40px;
	padding-left: 40px;
	color: #5865c3;
}

.hm-chat #chat-overlay {
	background: rgba(255, 255, 255, 0.1);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: none;
}


.hm-chat .chat-box {
	/*   display:none; */
	background: #efefef;
	position: fixed;
	right: 30px;
	bottom: 90px;
	width: 350px;
	max-width: 85vw;
	max-height: 85vh;
	border-radius: 5px;
	z-index: 999;
}

.hm-chat .chat-box-toggle {
	float: right;
	margin-right: 15px;
	cursor: pointer;
}

.hm-chat .chat-box-header {
	background: #4578bc;
	height: 70px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	color: white;
	text-align: center;
	font-size: 1.5rem;
	padding-top: 17px;
}

.hm-chat .chat-box-body {
	position: relative;
	max-height: 370px;
	height: 50vh;
	border: 1px solid #ccc;
	overflow: hidden;
}

.hm-chat #chat-input {
	background: #f4f7f9;
	width: 100%;
	position: relative;
	height: 47px;
	padding-top: 10px;
	padding-right: 50px;
	padding-bottom: 10px;
	padding-left: 15px;
	border: none;
	resize: none;
	outline: none;
	border: 1px solid #ccc;
	color: #888;
	border-top: none;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	overflow: hidden;
}

.hm-chat .chat-input > form {
	margin-bottom: 0;
}

.hm-chat #chat-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #ccc;
}

.hm-chat #chat-input::-moz-placeholder { /* Firefox 19+ */
	color: #ccc;
}

.hm-chat #chat-input:-ms-input-placeholder { /* IE 10+ */
	color: #ccc;
}

.hm-chat #chat-input:-moz-placeholder { /* Firefox 18- */
	color: #ccc;
}

.hm-chat .chat-submit {
	position: absolute;
	bottom: 3px;
	right: 10px;
	background: transparent;
	box-shadow: none;
	border: none;
	border-radius: 50%;
	color: #4578bc;
	width: 35px;
	height: 35px;
}

.hm-chat .chat-logs {
	padding: 15px;
	height: 100%;
	overflow: auto;
}

.chat-logs::-webkit-scrollbar-track .hm-chat {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

.chat-logs::-webkit-scrollbar .hm-chat {
	width: 5px;
	background-color: #f5f5f5;
}

.chat-logs::-webkit-scrollbar-thumb .hm-chat {
	background-color: #4578bc;
}


.hm-chat .chat-msg.user > .msg-avatar img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	float: left;
	width: 15%;
}

.hm-chat .chat-msg.self > .msg-avatar img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	float: right;
	width: 15%;
}

.hm-chat .cm-msg-text {
	background: white;
	padding: 10px 15px 10px 15px;
	color: #666;
	max-width: 75%;
	float: left;
	margin-left: 10px;
	position: relative;
	margin-bottom: 20px;
	border-radius: 30px;
}

.hm-chat .chat-msg {
	clear: both;
	margin-bottom: 15px;
}

.hm-chat .chat-msg.self > .cm-msg-text {
	float: right;
	margin-right: 10px;
	background: #4578bc;
	color: white;
}

.hm-chat .cm-msg-button > ul > li {
	list-style: none;
	float: left;
	width: 50%;
}

.hm-chat .cm-msg-button {
	clear: both;
	margin-bottom: 70px;
}

.hm-chat .cm-msg-image {
	background: white;
	padding: 10px 15px 10px 15px;
	color: #666;
	max-width: 75%;
	float: left;
	margin-left: 10px;
	position: relative;
	margin-bottom: 20px;
	border-radius: 30px;
}

.hm-chat .cm-msg-card {
	float: right;
	margin-right: 10px;
	max-width: 90%;
	background: #4578bc;
	color: white;
}

.hm-chat .card {
	width: inherit;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	flex: 0 0 auto;
}

.hm-chat .card-btn {
	width: 100%;
}

.hm-chat .card-btns-list {
	display: flex;
	flex-wrap: wrap-reverse;
}

.hm-chat p.card-title {
	font-size: 1rem;
}

p.card-text {
	font-size: 12px;
	line-height: 16px;
}

.hm-chat .card-body {
	padding: unset;
}

.hm-chat img.card-img {
	width: 96%;
	padding-top: 10%;
}

.hm-chat img.alone-img {
	width: 96%;
}

.hm-chat .button-list {
	list-style: none;
	padding: unset;
}

.hm-chat .list-group.list-group-flush.button-list .button {
	background-color: transparent;
	margin: 0px;
	padding: 0px;
}

.hm-chat .qr-list {
	list-style: none;
	flex-wrap: nowrap;
	overflow-x: auto;
	list-style: none;
	display: inline-flex;
	overflow-x: auto;
	max-width: 100%;
	-webkit-overflow-scrolling: touch;
}

.hm-chat .button.qr-btn .hm-chat .button,
.hm-chat .qr-list .button,
.hm-chat .qr-list .button:active,
.hm-chat .qr-list .button:focus,
.hm-chat .qr-list .button:hover {
	background-color: #0000;
	min-width: auto;
	padding: 0px;
	margin-bottom: 10px;
}

.hm-chat .card-list {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
}

.hm-chat .circle-icon {
	background: #ffc0c0;
	padding: 30px;
	border-radius: 50%;
}
