#weather-loading {
	height: 100%;
	text-align: center;
	min-width: 65px;
}

#weather-loading img {
	width: 40px;
}

#weather-content canvas {
	display: inline-block;
}

@media (max-width: 991px) {
	#weather-loading {
		min-width: 57px;
	}
}
