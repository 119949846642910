body {
	font-family: 'Encode Sans', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
	background-color: #fff;
}

h1 {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: -3px;
	padding-top: 0.43rem;
	padding-bottom: 0.43rem;
	font-family: Raleway, sans-serif;
	font-size: 2rem;
	line-height: 2.6rem;
	font-weight: 600;
	text-align: left;
	text-transform: uppercase;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.15rem;
	line-height: 1.65rem;
	font-weight: 600;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1rem;
	line-height: 1.55rem;
	font-weight: 600;
	text-transform: uppercase;
}

p {
	margin-bottom: 1rem;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 20px;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 40px;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 10px 20px;
	border-left: 5px solid #4578bc;
	color: #424242;
	font-size: 1.15rem;
	line-height: 1.85rem;
	font-weight: 500;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

.mediumimage,
.largeimage {
	border-color: transparent;
}

.button {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.button:hover {
	background-color: #2a5ea3;
}

.button.secondary {
	background-color: #d6dce0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	text-align: center;
	cursor: pointer;
}

.button.secondary:hover {
	border-color: #221f1f;
	background-color: #c4ccd1;
	background-image: none;
	opacity: 1;
}

.button.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 2px solid #c4ccd1;
	background-color: transparent;
	color: #585b5d;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button.tertiary:hover {
	background-color: #c4ccd1;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

.button.tertiary.language-toggle {
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	border-width: 1px;
	color: #000;
	font-size: 0.75rem;
	line-height: 1.2rem;
	font-weight: 500;
}

.button.tertiary.language-toggle:hover {
	border-color: rgba(69, 120, 188, 0.41);
	background-color: rgba(69, 120, 188, 0.41);
}

.button.tertiary.language-toggle {
	display: none;
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	border-width: 1px;
	color: #000;
	font-size: 0.75rem;
	line-height: 1.2rem;
	font-weight: 500;
}

.button.tertiary.language-toggle:hover {
	border-color: rgba(69, 120, 188, 0.41);
	background-color: rgba(69, 120, 188, 0.41);
}

.button.alert-button {
	width: 200px;
	padding-right: 2.25rem;
	padding-left: 0.75rem;
	border-style: none;
	background-color: #ed9648;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrow-right_black.svg');
	background-position: 89% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #000;
}

.button.alert-button:hover {
	padding-left: 1.5rem;
	border-color: #ed9648;
	background-position: 96% 50%;
	background-size: 18px 18px;
}

.button.inside-flight-button {
	height: 40px;
	min-width: 125px;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 11px 8px;
	border-radius: 0px 4px 4px 0px;
	background-color: #4578bc;
	font-weight: 600;
}

.button.inside-flight-button:hover {
	border-color: #033466;
	background-color: #2a5ea3;
	margin-top: 0;
	padding: 11px 8px;
}

.button.slideshow-button {
	display: inline-block;
	height: auto;
	margin-top: 0.75rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	float: left;
	clear: left;
	background-color: #4578bc;
	font-size: 0.85em;
	line-height: 1.5rem;
}

.button.slideshow-button:hover {
	background-color: #2a5ea3;
}

.button.slideshow-button.corp-ss-button {
	display: none;
}

.button.cb-button {
	height: auto;
	-webkit-appearance: none;
}

.button.cb-button:hover {
	background-color: #2a5ea3;
}

.button.accent-button {
	height: auto;
	min-width: 150px;
}

.button.accent-button:hover {
	background-color: #05cccc;
}

.button.promo-fake-button {
	display: block;
	height: auto;
	margin-right: 0rem;
	float: left;
	background-color: #4578bc;
}

.button.promo-fake-button:hover {
	background-color: #2a5ea3;
}

.form-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.container {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container.footer-container {
	display: block;
	max-width: 1500px;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1500px;
	padding: 0.5rem 1.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.inside-content-container {
	padding-top: 2rem;
	padding-right: 6.5vw;
	padding-left: 6.5vw;
	background-color: hsla(0, 0%, 100%, 0.9);
}

.container.parking-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 100%;
}

.container.parking-container .parking-error {
	background: #fff;
	color: #ab0606;
	padding: 5px;
	margin-top: 10px;
	line-height: 1.4rem;
	display: none;
}

.container.promo-and-cb-container {
	max-width: none;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container.inside-page-container {
	max-width: none;
}

.container.page-title-container {
	position: relative;
	left: 0px;
	right: 0px;
	display: block;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 4vw;
	background-color: hsla(0, 0%, 100%, 0.75);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link {
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: #727780;
	color: #4578bc;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.right-side-col {
	position: relative;
	width: 16%;
	max-width: 250px;
	min-width: 160px;
	margin-left: 3vw;
	padding-top: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
}

.right-side-col p {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-weight: 600;
}

.right-side-col h4 {
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-weight: 600;
}

.right-side-col ul {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col ol {
	padding-left: 20px;
	font-size: 0.9rem;
}

.right-side-col a {
	border-bottom: 1px solid #629edc;
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
}

.right-side-col a:hover {
	border-bottom-color: transparent;
}

.logo {
	width: 100%;
}

.logo-link {
	display: block;
	width: 150px;
	margin-right: 3rem;
	margin-left: 0vw;
	padding: 0.5rem 0rem 0rem;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.logo-link.w--current {
	position: relative;
	top: -29px;
}

.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dropdown-list {
	position: absolute;
	top: 43px;
	display: none;
	height: 0px;
	border-top: 4px solid #f9b604;
	background-color: hsla(0, 0%, 100%, 0.93);
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
	opacity: 0;
}

.dropdown-list.w--open {
	left: 0px;
	top: 43px;
	min-width: 200px;
	padding: 0.25rem 0.25rem 0.5rem;
	background-color: #ffdf1c;
}

.dropdown-link {
	display: block;
	padding: 0.7rem 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.dropdown-link:hover {
	padding-right: 0.65rem;
	padding-left: 1.15rem;
	border-left: 5px none #ed1b2e;
	border-radius: 2px;
	background-color: rgba(249, 182, 4, 0.28);
	background-image: none;
	box-shadow: none;
}

.dropdown-link.w--current {
	background-color: #034a94;
	color: #fff;
	cursor: pointer;
}

.dropdown-link.w--current:hover {
	padding-right: 0.85rem;
	padding-left: 0.85rem;
}

.dropdown-link.language-link {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	background-color: #fff;
}

.dropdown-link.language-link:hover {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	color: #2e2e2e;
}

.hero-rotator-section {
	position: relative;
	display: block;
	height: 60vh;
	max-height: 620px;
	min-height: 570px;
	margin-bottom: 0rem;
}

.hero-rotator {
	width: 100%;
	height: 100%;
	background-color: #f9f9f9;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/brushed-alum-dark_flop.png');
	background-position: 0px 0px;
	background-size: 200px;
}

.hero-rotator.corp-hero-rotator {
	background-color: #005f9b;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/brushed-alum-dark_flop.png');
	background-position: 0px 0px;
	background-size: 200px;
}

.slideshow-arrow {
	left: auto;
	top: auto;
	right: 0px;
	bottom: 0px;
	width: 2rem;
	height: 2rem;
}

.slideshow-arrow.left-arrow {
	left: 4vw;
	right: auto;
	display: block;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.left-arrow:hover {
	background-color: #4578bc;
}

.slideshow-arrow.left-arrow.corp-ss-arrow {
	background-color: #000;
	cursor: pointer;
}

.slideshow-arrow.right-arrow {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	display: block;
	margin-left: 2.4rem;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.right-arrow:hover {
	background-color: #4578bc;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slideshow-arrow.right-arrow.corp-ss-rt-arrow {
	background-color: #000;
	cursor: pointer;
}

.slideshow-arrow.right-arrow {
	left: 4vw;
	right: auto;
	bottom: 0.5rem;
	display: block;
	margin-left: 2.4rem;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.right-arrow:hover {
	background-color: #4578bc;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slideshow-arrow.left-arrow {
	left: 4vw;
	right: auto;
	bottom: 0.5rem;
	display: block;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.left-arrow:hover {
	background-color: #4578bc;
}

.slideshow-arrow.right-slide-arrow {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	display: block;
	margin-left: 2.4rem;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.right-slide-arrow:hover {
	background-color: #4578bc;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slideshow-arrow.left-slide-arrow {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	display: block;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.left-slide-arrow:hover {
	background-color: #4578bc;
}

.slidenav {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	display: block;
	width: 100px;
	height: 2rem;
	margin-right: auto;
	margin-left: 5rem;
	padding-top: 13px;
	font-size: 0.65rem;
	text-align: left;
}

.arrow-icon {
	display: block;
	margin: auto;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: skew(3deg, 0deg);
	-ms-transform: skew(3deg, 0deg);
	transform: skew(3deg, 0deg);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.9rem;
	line-height: 0.9rem;
}

.arrow-icon:hover {
	color: #fff;
}

.arrow-icon.right-arrow-icon {
	left: 0px;
	right: 0px;
	display: block;
	margin: auto;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.hero-slide {
	overflow: hidden;
}

.home-content-section {
	display: block;
	padding-top: 3rem;
	padding-bottom: 3rem;
	overflow: hidden;
}

.home-content-section.hcs-row-1 {
	padding-bottom: 7rem;
	background-color: #f9f9f9;
	text-align: left;
}

.home-content-section.hcs-row-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 6rem;
	padding-bottom: 3rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: #fff;
	text-align: left;
}

.home-content-section.hcs-row-4 {
	padding: 6rem 4vw;
	background-position: 50% 50%;
	background-size: 200px, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
	text-align: left;
}

.home-content-section.hcs-row-4.hcs-row-4-corp {
	background-position: 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
}

.home-content-section.home-content-section-corp {
	background-color: transparent;
	display: block;
}

.home-content-section.hcs-row-4-corp {
	padding: 7rem 4vw;
	background-size: 12px, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
	text-align: left;
}

.footer-section {
	padding-top: 3rem;
	background-color: #f5f5f5;
}

.signoff-wrapper {
	position: relative;
	margin-top: 1rem;
	padding-bottom: 1.25rem;
	background-color: #384048;
}

.footer-grip-link {
	display: block;
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 32px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-position: 50% 50%;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	opacity: 0.75;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link:hover {
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.grip-logo-text-box {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
}

.copyright-paragraph {
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 0rem;
	color: #dadada;
	font-size: 0.8rem;
	font-weight: 400;
}

.signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dadada;
	font-size: 0.8rem;
	text-decoration: none;
}

.signoff-link:hover {
	color: #fff;
}

.footer-paragraph {
	margin-bottom: 0.65rem;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.footer-nav-list {
	-webkit-column-count: 1;
	column-count: 1;
}

.footer-nav-list-item {
	display: block;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	font-weight: 400;
}

.footer-list-item-link {
	padding-right: 0.5rem;
	color: hsla(0, 0%, 100%, 0.85);
	text-decoration: none;
}

.footer-list-item-link:hover {
	color: #fff;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	margin-top: 0.5rem;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	float: none;
}

.copyright-wrapper {
	display: inline-block;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
}

.textlink-middleout {
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
}

.textlink-middleout:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.textlink-middleout.footer-list-item-link {
	padding-right: 0rem;
	color: #2e2e2e;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
	cursor: pointer;
}

.textlink-middleout.footer-list-item-link:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.textlink-middleout.signoff-link {
	display: inline-block;
	color: #dadada;
	font-weight: 400;
}

.textlink-middleout.signoff-link:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.footer-social-icon-image {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #424242;
	font-size: 1rem;
}

.locations-options-wrapper {
	display: block;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.more-options-button {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #221f1f;
	font-size: 0.95rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-heading {
	margin-top: 0rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
}

.text-link {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

.text-link.language {
	color: #5e5e5f;
	cursor: pointer;
}

.text-link.language:hover {
	border-bottom-style: none;
	box-shadow: inset 0 -2px 0 0 #629edc;
}

.text-link.language.cuurent-lang {
	border-bottom-color: transparent;
	box-shadow: inset 0 -2px 0 0 #629edc;
}

.heading-link {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	text-decoration: none;
	cursor: pointer;
}

.heading-link:hover {
	border-bottom-color: transparent;
	color: #7c8186;
}

.privacy-terms-wrapper {
	display: inline-block;
}

.less-options-button {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #221f1f;
	font-size: 0.95rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.hero-image-wrapper {
	position: relative;
	overflow: hidden;
	width: 80%;
	height: 100%;
	float: right;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.hero-image-wrapper.corp-hero-1 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.footer-section-wrap {
	position: relative;
	z-index: 2;
}

.success-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
}

.error-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

.feature-card-bg-image {
	height: 200px;
	margin-top: 4px;
	margin-right: 4px;
	margin-left: 4px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/placeholder-image.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.feature-card-heading {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 0px 0rem;
	font-family: 'Chunkfive webfont';
	color: #fff;
	font-size: 1.5rem;
	line-height: 1.9rem;
	font-weight: 400;
	text-align: right;
	letter-spacing: 0.05rem;
}

.parking-field-label {
	color: #fff;
	font-weight: 500;
}

.nav-dd-triangle {
	position: absolute;
	right: 50%;
	bottom: 0px;
	display: none;
	border-right: 7px solid transparent;
	border-bottom: 8px solid #f9b604;
	border-left: 7px solid transparent;
}

.dropdown-toggle {
	padding: 9px 1.15rem 0.5rem 0.5rem;
	font-family: 'Encode Sans', sans-serif;
	font-weight: 400;
}

.picker-dd-arrow {
	margin-right: 0.25rem;
	font-size: 0.6rem;
	line-height: 0.6rem;
	font-weight: 400;
}

.submit-button {
	display: none;
}

.upper-left-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-transform: uppercase;
}

.cb-feature-heading {
	margin-top: 0rem;
	color: #034a94;
	font-size: 3rem;
	line-height: 3.5rem;
}

.cb-feature-heading.reversed {
	margin-top: 0rem;
	color: #fff;
}

.feature-cb-paragraph {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 2.1rem;
}

.feature-cb-paragraph.white-feature-cb-para {
	color: #fff;
	font-family: inherit;
}

.tab-triangle {
	position: absolute;
	left: 50%;
	display: none;
	height: 16px;
	border-top: 10px solid #165b89;
	border-right: 12px solid transparent;
	border-left: 12px solid transparent;
	opacity: 0;
	-webkit-transform: translate(-50%, 0px);
	-ms-transform: translate(-50%, 0px);
	transform: translate(-50%, 0px);
}

.language-dd-list {
	display: none;
	background-color: #dce3e7;
}

.language-dd-list.w--open {
	top: 38px;
	right: 0px;
	z-index: 2000;
	background-color: #c8e3ff;
}

.search-button {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 40px;
	height: 38px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 0.34;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px none #bec7d1;
}

.footer-header {
	margin-top: 0rem;
	color: #034a94;
	font-size: 1rem;
	line-height: 1.5rem;
}

.footer-header.filler-hack {
	color: transparent;
	font-family: inherit;
}

.footer-lower-parent {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1.75rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	border-top: 2px solid #c3b9a4;
}

.footer-upper-parent {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.nav-section-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.inside-table-text.inside-table-header {
	color: #fff;
	font-size: 1rem;
	line-height: 1.4rem;
}

.inside-table-text.inside-table-header-centered {
	color: #fff;
	font-size: 1rem;
	line-height: 1.4rem;
	text-align: center;
}

.inside-table-text.date-text {
	font-size: 0.85rem;
	font-weight: 500;
	text-transform: uppercase;
}

.inside-table-text.inside-table-header-tablesaw {
	color: #fff;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 600;
}

.inside-table-text.table-text-bolder {
	font-weight: 500;
}

.header-right-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
}

.alert-section {
	position: relative;
	display: block;
	background-color: #033466;
}

.alert-wrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding: 5rem 4rem 3rem;
}

.alert-heading {
	margin-top: 0rem;
	font-family: 'Exo 2';
	color: #fff;
	font-size: 3rem;
	line-height: 3.5rem;
	font-weight: 600;
}

.alert-paragraph {
	color: #fff;
	font-size: 1.1rem;
}

.alert-close {
	position: absolute;
	top: 22px;
	right: 0px;
	width: 60px;
	height: 60px;
	margin-right: 1rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/close_white.svg');
	background-position: 50% 50%;
	background-size: 28px 28px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.alert-close:hover {
	opacity: 0.5;
}

.column {
	padding-top: 1.625rem;
}

.spinner-country-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 150px;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
	background-color: #fff;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/60px_wikipedia_Flag_of_Spain_160px_wikipedia_Flag_of_Spain.png');
	background-position: 11px 50%;
	background-size: 35px;
	background-repeat: no-repeat;
}

.spinner-destination {
	margin-top: 0rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 1.25rem;
}

.footer-cb {
	width: 20%;
}

.footer-cb.footer-cb-1 {
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-cb.footer-cb-2 {
	width: 27%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-cb.footer-cb-2 {
	width: 20%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-cb.footer-cb-4 {
	width: 27%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-cb.footer-cb-3 {
	width: 27%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-subhead {
	font-weight: 500;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
}

.inside-content-section {
	padding-top: 153px;
}

.inside-flight-schedule-wrapper {
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 4rem;
	text-align: left;
}

.inside-arrival-departure-tabs {
	display: block;
	text-align: left;
}

.inside-flight-tab-link {
	margin-right: 0.5rem;
	padding-top: 12px;
	padding-left: 3.5rem;
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrivals_4c4c4c_50pct.svg');
	background-position: 10px 3px;
	background-size: 40px 30px;
	background-repeat: no-repeat;
	opacity: 0.83;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #797676;
	font-size: 1.1rem;
	font-weight: 500;
}

.inside-flight-tab-link:hover {
	opacity: 1;
}

.inside-flight-tab-link.w--current {
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrivals_blue3.svg');
	background-position: 10px 3px;
	background-size: 40px 30px;
	background-repeat: no-repeat;
	box-shadow: inset 0 -4px 0 0 #629edc;
	opacity: 1;
	font-weight: 500;
}

.inside-flight-tab-link.inside-departures-tab {
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/departures_4c4c4c_50pct.svg');
	background-position: 10px 50%;
	background-size: 42px 24px;
	opacity: 0.83;
	color: #797676;
	font-weight: 500;
}

.inside-flight-tab-link.inside-departures-tab:hover {
	box-shadow: inset 0 -4px 0 0 #629edc;
	opacity: 1;
}

.inside-flight-tab-link.inside-departures-tab.w--current {
	padding-left: 3.5rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/departures_blue4.svg');
	background-position: 10px 50%;
	background-size: 42px 24px;
	background-repeat: no-repeat;
	box-shadow: inset 0 -4px 0 0 #629edc;
	opacity: 1;
	color: #000;
}

.inside-tab-1-arrivals {
	padding-top: 1rem;
}

.inside-flight-form-block {
	margin-top: 0.5rem;
	margin-bottom: 1.25rem;
}

.inside-flight-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inside-flight-form-text-field {
	height: 40px;
	margin-bottom: 0px;
	padding-top: 7px;
	border: 1px solid #a5a5a5;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: hsla(0, 0%, 100%, 0.54);
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #252020;
	font-size: 0.8rem;
}

.inside-flight-form-text-field::-webkit-input-placeholder {
	color: #676767;
	font-size: 0.8rem;
}

.inside-flight-form-text-field:-ms-input-placeholder {
	color: #676767;
	font-size: 0.8rem;
}

.inside-flight-form-text-field::placeholder {
	color: #676767;
	font-size: 0.8rem;
}

.inside-flight-table {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.inside-table-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.inside-table-row.no-results {
	text-align: center;
	display: block;
}

.inside-table-row.inside-header-row {
	min-height: 38px;
	padding: 4px 0.25rem;
	border-bottom: 2px none #033466;
	background-color: #033466;
	color: #fff;
}

.inside-table-row.new-day-row {
	border-bottom-color: rgba(98, 158, 220, 0.4);
	background-color: rgba(98, 158, 220, 0.4);
	color: #000;
}

.inside-table-row.new-day-row .separator b {
	display: none;
}

.inside-table-row.inside-header-row-tablesaw {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 38px;
	padding: 4px 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 2px solid #629edc;
	background-color: #034a94;
	color: #fff;
}

.flights-table tbody td b {
	color: #034a94;
}

.flights-table thead tr:first-child th,
.flight-schedule-table thead tr:first-child th {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.inside-table-cell {
	min-height: 26px;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	align-items: center;
	font-weight: normal;
}

.inside-table-cell.airline-name-cell {
	width: 20%;
}

.inside-table-text img {
	max-height: 23px;
}

.inside-table-text.no-results {
	text-align: center;
}

.inside-table-cell.flight-number-cell {
	width: 21%;
}

.inside-table-cell.city-to-from-cell {
	width: 23%;
	line-height: 1rem;
}

.inside-table-cell.time-cell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 11%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inside-table-cell.more-info-cell {
	width: 12%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.inside-table-cell.inside-lg-cell-tablesaw {
	width: 26%;
}

.inside-table-cell.inside-small-cell-tablesaw {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 15%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inside-table-cell.inside-small-cell-tablesaw {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 15%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inside-table-cell.status-cell {
	width: 13%;
	line-height: 1rem;
}

.more-flight-info-link {
	display: block;
	width: 24px;
	height: 24px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/info-circle_yellow-blue-taller.svg');
	background-position: 50% 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.more-flight-info-link:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/info-circle_blue-taller.svg');
	background-size: 22px;
}

.module-flights .inside-flight-dd-fields-wrap .flight-status-dd-field {
	margin-bottom: 0px;
	margin-right: 10px;
	border: 1px solid #a5a5a5;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.flight-schedule-filter-dd {
	width: 28%;
	margin-bottom: 0px;
	border: 1px solid #a5a5a5;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.inside-flight-search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
}

.inside-tab-content {
	overflow: unset;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	background-color: #fff;
}

.inside-table-status-text {
	display: inline;
	padding: 1px 3px;
	color: #000;
	font-size: 0.9rem;
	text-align: left;
}

.inside-table-status-text.landed {
	background-color: #e0dbc4;
	line-height: 1.3rem;
}

.inside-table-status-text.on-time {
	background-color: #adf3cc;
	line-height: 1.3rem;
}

.inside-table-status-text.delayed {
	background-color: #ffec62;
	line-height: 1.3rem;
}

.inside-table-status-text.cancelled {
	background-color: #ffb2b2;
	line-height: 1.3rem;
}

.inside-table-status-text.boarding {
	background-color: #ffec62;
	line-height: 1.3rem;
}

.inside-table-status-text.departed {
	background-color: #e0dbc4;
	line-height: 1.3rem;
}

.inside-table-status-text.gate-closed {
	background-color: #ffc877;
	line-height: 1.3rem;
}

.inside-flight-dd-fields-wrap {
	display: flex;
	justify-content: space-between;
	width: 59%;
}

.inside-flight-schedule-dd-fields-wrap {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.breadcrumbs-list {
	margin-bottom: 2rem;
	padding-left: 0px;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.inside-tab-2-departures {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-top: 1px none rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.inside-table-row-tablesaw {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #cecab7;
}

.inside-table-text-tablesaw {
	color: #000;
	font-size: 0.9rem;
	line-height: 1.4rem;
	text-align: left;
}

.inside-table-status-text-tablesaw {
	display: inline;
	padding: 1px 3px;
	color: #000;
	font-size: 0.9rem;
	font-weight: 500;
	text-align: left;
}

.inside-table-status-text-tablesaw.landed {
	background-color: #e0dbc4;
	font-size: 0.9rem;
}

.inside-table-status-text-tablesaw.on-time {
	background-color: #adf3cc;
	font-size: 0.9rem;
}

.inside-table-status-text-tablesaw.delayed {
	background-color: #ffec62;
	font-size: 0.9rem;
}

.inside-table-status-text-tablesaw.cancelled {
	background-color: #ffb2b2;
	font-size: 0.9rem;
}

.inside-table-status-text-tablesaw.boarding {
	background-color: #adf3cc;
	font-size: 0.9rem;
}

.inside-table-status-text-tablesaw.departed {
	background-color: #e0dbc4;
	font-size: 0.9rem;
}

.tablesaw-outer-wrap {
	display: none;
}

.block-quote {
	border-left-color: #4578bc;
	color: #424242;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #424242;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.insta-tile-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border: 1px solid #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 9px -8px rgba(0, 0, 0, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: none;
}

.insta-tile-link:hover {
	box-shadow: none;
	opacity: 0.6;
}

.insta-tile-link.big-pic {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
}

.insta-tile-link.insta-1 {
	width: 100%;
	height: 100%;
	border-color: #fff;
}

.nav-search-button-icon {
	width: 1em;
	height: 1.375em;
}

.nav-search-button-close-icon {
	display: none;
	width: 1em;
	height: 1.375em;
}

.slider-caption {
	position: absolute;
	left: 0px;
	top: 50%;
	bottom: 0px;
	width: 50%;
	height: 100%;
	max-width: 650px;
	min-width: 545px;
	margin-left: 4vw;
	padding-bottom: 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	color: #fff;
	font-size: 1.125em;
	line-height: 1.65em;
}

.slide-caption-inner-wrap {
	position: relative;
	top: 50%;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slideshow-header {
	max-width: none;
	padding: 0.5rem 1rem;
	border-left: 4px solid #4578bc;
	background-color: hsla(0, 0%, 100%, 0.75);
	color: #000;
	font-size: 2.75rem;
	line-height: 3.15rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.slideshow-header.corp-ss-header {
	border-left-color: #033466;
}

.slideshow-caption {
	display: inline-block;
	max-width: 650px;
	margin-bottom: 0.7rem;
	padding: 0.5rem 1rem;
	float: left;
	background-color: hsla(0, 0%, 100%, 0.75);
	color: #000;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 300;
}

.insta-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 520px;
	height: 520px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: #000;
}

.quick-search-results-outer {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	opacity: 0;
}

.instagram-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 500px;
	margin-top: 2rem;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.cb-header-wrap {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
	text-align: center;
}

.content-box {
	width: 50%;
	height: auto;
	min-height: 500px;
	padding-top: 5rem;
	padding-right: 1rem;
	padding-bottom: 6rem;
}

.content-box.cb-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0vw;
	padding-top: 4rem;
	padding-right: 0rem;
	padding-bottom: 4rem;
	justify-content: center;
	align-items: flex-start;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
}

.content-box.cb-1 {
	margin-right: 2rem;
	padding-top: 4.5rem;
	padding-right: 0rem;
}

.content-box.cb-1.parking-text {
	min-height: 0px;
	margin-right: 0rem;
	padding-right: 2rem;
	padding-left: 4vw;
}

.content-box.callout-cb {
	width: 100%;
	min-height: 0px;
	padding: 3rem 3vw;
	background-color: rgba(6, 95, 173, 0.9);
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
	text-align: center;
}

.content-box.promo-cb {
	width: 100%;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-bottom: 3rem;
}

.content-box-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 4rem;
	padding-left: 4rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 4px none #4c4c4c;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.content-box-wrap.cbw-row-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1040px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.form-block {
	width: 100%;
}

.two-item-form-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.date-item {
	margin-right: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.select-airport {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.text-field {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.text-field::-webkit-input-placeholder {
	color: #2e2e2e;
	font-family: inherit;
}

.text-field:-ms-input-placeholder {
	color: #2e2e2e;
	font-family: inherit;
}

.text-field::placeholder {
	color: #2e2e2e;
	font-family: inherit;
}

.select-field-2 {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #2e2e2e;
}

.select-field-3 {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.text-field-2 {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.text-field-2::-webkit-input-placeholder {
	color: #2e2e2e;
	font-family: inherit;
}

.text-field-2:-ms-input-placeholder {
	color: #2e2e2e;
	font-family: inherit;
}

.text-field-2::placeholder {
	color: #2e2e2e;
	font-family: inherit;
}

.parking-form-header {
	color: #fff;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.parking-form-outer-wrap {
	width: 80%;
}

.parking-form-outer-wrap.corp-form-outer-wrap {
	display: none;
}

.insta-small-image-wrap {
	width: 260px;
	height: 260px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.promo-slide-item-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: #f9f9f9;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/brushed-alum-dark_flop.png');
	background-position: 0px 0px;
	background-size: 200px;
}

.promo-slide-item-wrapper.corp-slide-item-wrapper {
	background-color: #fff;
	position: relative;
}

.promo-link-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	margin-bottom: 0rem;
	transition: none;
	text-decoration: none;
}

.promo-link-wrapper:hover {
	border-bottom-color: #6dcdf4;
}

.promo-item-outer-text-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
	padding: 2rem 80px 2rem 3vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	color: #000;
}

.promo-item-heading-copy {
	margin-top: 0rem;
	color: #000;
	font-size: 2rem;
	line-height: 2.7rem;
}

.promo-arrow-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.promo-arrow-link:hover {
	background-color: #4578bc;
	cursor: pointer;
}

.promo-arrow-link.promo-arrow-link-corp {
	background-color: #4578bc;
	cursor: pointer;
}

.promo-arrow-link.promo-arrow-link-corp:hover {
	background-color: #2a5ea3;
	cursor: pointer;
}

.promo-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 100%;
	min-width: 0%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 520px;
	-ms-flex: 1 0 520px;
	flex: 1 0 520px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	color: #000;
}

.promo-image.promo-image-3 {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.promo-slider {
	height: 554px;
	background-color: transparent;
}

.promo-slide {
	width: 100%;
	margin-right: 0%;
	margin-left: 0%;
}

.slide-nav {
	bottom: -56px;
	font-size: 0.65rem;
}

.promo-item-inner-text-wrap {
	width: 100%;
}

.promo-arrow-icon {
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.5rem;
}

.accent-divider {
	display: block;
	width: 100px;
	height: 2px;
	margin: 0.75rem auto;
	background-color: #c3b9a4;
}

.social-media-link-block-2 {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.social-media-link-block-2.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	padding: 2px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 2px solid transparent;
	border-radius: 50%;
	background-color: #4578bc;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	padding: 2px;
	border: 2px solid #2a5ea3;
	background-color: #2a5ea3;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.promo-paragraph {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.95rem;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.temperature {
	padding-top: 3px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #5e5e5f;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
}

.header-sect {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 10000;
	padding: 0.5rem 2vw 0.25rem 4vw;
	border-bottom: 4px solid #c3b9a4;
	background-color: hsla(0, 0%, 100%, 0.95);
}

.header-sect.corp-header-sect {
	border-bottom-color: #033466;
}

.nav-bar-widget-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
	align-items: center;
}

.search-go-button {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.75em 1.5em;
	background-color: #7b680a;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03em;
	text-decoration: none;
}

.search-go-button:hover {
	background-color: #000;
	color: #fff;
}

.search-go-button.flight-quick-search-button {
	display: none;
	width: 0rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: #034a94;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrow-right.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 1rem;
	line-height: 1em;
	text-transform: uppercase;
}

.search-go-button.flight-quick-search-button:hover {
	background-color: #b69b11;
}

.search-text-field-2 {
	width: 150px;
	margin-bottom: 0px;
	padding-right: 40px;
	padding-bottom: 8px;
	padding-left: 0.25rem;
	border-style: none;
	background-color: transparent;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.8rem;
	line-height: 1.4rem;
}

.search-text-field-2:focus {
	width: 175px;
	box-shadow: 0 1px 0 0 #8f9296;
}

.search-text-field-2::-webkit-input-placeholder {
	color: #000;
	text-align: left;
}

.search-text-field-2:-ms-input-placeholder {
	color: #000;
	text-align: left;
}

.search-text-field-2::placeholder {
	color: #000;
	text-align: left;
}

.nav-dd-list.w--open {
	width: auto;
	min-width: 150px;
	background-color: #fff;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.weather-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-bottom: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #797676;
}

.subnav-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50px;
	float: right;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.weather-icon {
	width: 32px;
	min-width: 32px;
	margin-right: 0.25rem;
}

.weather-icon img {
	margin-bottom: -3px;
}

.flight-search-form-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	padding: 0px 1rem 0.5rem;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: hsla(0, 0%, 100%, 0.93);
}

.logo-wrapper {
	position: relative;
	width: 20%;
}

.megamenu-section-header {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 6px 10px;
	color: #034a94;
	font-size: 0.85rem;
	font-weight: 600;
	text-transform: uppercase;
}

.header-right-upper-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50px;
	padding-top: 0.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.flight-search-dd-icon {
	top: 2px;
	margin-right: 0px;
	font-size: 0.78rem;
}

.dd-link-wrap {
	padding: 10px;
}

.subnav-wrap {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.search-form-block-2 {
	position: relative;
	z-index: 100;
	height: 100%;
	margin-bottom: 0px;
}

.search-dd {
	top: 0px;
	right: 0px;
}

.search-dd.w--open {
	top: 100%;
	width: 472px;
	background-color: #fff;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.translate-embed {
	display: none;
	width: 180px;
	height: 38px;
}

.flight-quick-search-results-wrap {
	overflow: auto;
	width: 100%;
	max-height: 160px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.category-nav-link-2 {
	position: relative;
	display: block;
	margin-right: 0.75rem;
	padding: 12px 1rem;
	color: #000;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 500;
	letter-spacing: 0.03rem;
	text-decoration: none;
	cursor: pointer;
}

.category-nav-link-2:hover {
	box-shadow: inset 0 -4px 0 0 #629edc;
	color: #000;
}

.category-nav-link-2.current-category {
	box-shadow: inset 0 -4px 0 0 #629edc;
	font-weight: 500;
}

.flight-info-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.3rem 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
}

.flight-info-link:hover {
	background-color: rgba(3, 52, 102, 0.1);
}

.flight-info-link.tinted-row {
	-webkit-transition-property: all;
	transition-property: all;
}

.flight-info-link.tinted-row:hover {
	background-color: rgba(3, 52, 102, 0.07);
}

.megamenu-wrap {
	padding-right: 0.5rem;
	padding-bottom: 1rem;
	padding-left: 0.5rem;
}

.topnav-link-2 {
	display: none;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding: 0.13rem 0.25rem;
	float: left;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.8rem;
	line-height: 1.4rem;
	text-align: left;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
}

.topnav-link-2:hover {
	position: relative;
	background-color: transparent;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #629edc;
	text-align: left;
}

.topnav-link-2.picker-link {
	z-index: 2000;
	display: none;
	height: 38px;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.translate-wrap {
	display: block;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.8rem;
	line-height: 1.4rem;
	font-weight: 500;
}

.search-button-2 {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 40px;
	height: 38px;
	border-radius: 4px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk.svg');
	background-position: 50% 45%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	opacity: 0.6;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-button-2:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk_4578bc.svg');
	background-size: 16px 16px;
	opacity: 1;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 1rem;
}

.form-field.flight-search-field {
	width: 100%;
	height: 2.5rem;
	margin-bottom: 0em;
	padding-right: 1em;
	padding-left: 1em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	background-color: transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-weight: 400;
}

.form-field.flight-search-field:focus {
	font-size: 1em;
}

.form-field.flight-search-field::-webkit-input-placeholder {
	color: #000;
	font-size: 0.9em;
	line-height: 1.2rem;
}

.form-field.flight-search-field:-ms-input-placeholder {
	color: #000;
	font-size: 0.9em;
	line-height: 1.2rem;
}

.form-field.flight-search-field::placeholder {
	color: #000;
	font-size: 0.9em;
	line-height: 1.2rem;
}

.form-field.flight-search-field {
	width: 272px;
	height: 35px;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-bottom: 9px;
	padding-left: 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.2);
	border-radius: 4px 0px 0px 4px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-size: 0.88rem;
	font-weight: 400;
}

.form-field.flight-search-field:focus {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-size: 0.88rem;
}

.form-field.flight-search-field::-webkit-input-placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.flight-search-field:-ms-input-placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.flight-search-field::placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.flight-search-field {
	width: 272px;
	height: 35px;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-bottom: 5px;
	padding-left: 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.2);
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-size: 0.88rem;
	font-weight: 400;
}

.form-field.flight-search-field:focus {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-size: 0.88rem;
}

.form-field.flight-search-field::-webkit-input-placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.flight-search-field:-ms-input-placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.flight-search-field::placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.mini-flight-search-field {
	width: 100%;
	height: 35px;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-bottom: 5px;
	padding-left: 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-size: 0.88rem;
	font-weight: 400;
}

.form-field.mini-flight-search-field:focus {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-size: 0.88rem;
}

.form-field.mini-flight-search-field::-webkit-input-placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.mini-flight-search-field:-ms-input-placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.form-field.mini-flight-search-field::placeholder {
	color: #000;
	font-size: 0.88rem;
	line-height: 1.6rem;
}

.table-cell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.table-cell.view-flight-cell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.table-cell.dd-type {
	width: 9%;
}

.table-cell.dd-type img.flight-type {
	width: 30px;
	height: 20px;
}

.table-cell.dd-time-date {
	width: 20%;
}

.table-cell.dd-airline {
	width: 20%;
}

.table-cell.dd-status {
	width: 18%;
}

.table-cell.dd-airport-flight {
	width: 28%;
}

.table-cell.dd-notification {
	min-width: 63px;
	flex-basis: 63px;
}

.table-cell.dd-notification .bt_bizTweet {
	margin-right: 0px;
}

.main-nav-link-toggle {
	width: 100%;
	padding: 0px;
	box-shadow: none;
}

.main-nav-link-toggle:hover {
	box-shadow: inset 0 -2px 0 0 #629edc;
}

.main-nav-link-toggle.w--open {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

.megamenu-column {
	display: block;
	margin-right: 1rem;
	padding-top: 10px;
	padding-bottom: 10px;
}

.megamenu-column.extra-wide {
	width: 50%;
}

.flight-search-dd-toggle {
	display: inline-block;
	margin-right: 0px;
	padding: 0.25rem 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-radius: 4px;
	background-color: #4578bc;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-align: center;
}

.flight-search-dd-toggle:hover {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #2a5ea3;
}

.flight-search-dd-toggle.w--current {
	background-color: transparent;
	color: #b69b11;
	cursor: pointer;
}

.flight-search-dd-toggle.w--open {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.main-nav-dd-widget {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
}

.search-outer-wrapper {
	display: block;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.quick-search-text-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.flight-search-dd-widget {
	position: absolute;
	right: 0px;
	bottom: 5px;
	z-index: 1000;
	display: none;
	margin-right: 0px;
	margin-left: 0px;
}

.megamenu-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.main-nav-text {
	position: relative;
	display: block;
	width: 100%;
	padding: 0.5rem;
	color: #000;
	text-decoration: none;
}

.main-nav-text:hover {
	color: #4578bc;
}

.main-nav-text.w--current {
	box-shadow: none;
	color: #4578bc;
}

.main-nav-text.w--current:hover {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

.upper-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.table-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.45rem 0.5rem 0.45rem 1.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px none #c5c2c2;
	font-size: 0.83rem;
	line-height: 1.25rem;
}

.table-row.white-row {
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.table-row.quick-search-tinted {
	padding: 0rem;
	background-color: rgba(3, 52, 102, 0.08);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.table-row.quick-search-tinted {
	padding: 0rem;
	background-color: rgba(3, 52, 102, 0.05);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.text-block-2 {
	display: none;
}

.flight-info-arrow-link {
	display: none;
	width: 30px;
	height: 30px;
	padding: 8px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dd-link {
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 4px 10px;
	font-size: 0.85rem;
	font-weight: 500;
}

.dd-link:hover {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.dd-link.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.flight-quick-search-link-wrap-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 0px;
	padding: 0rem 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0;
}

.view-all-flights-2 {
	display: inline-block;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	margin-left: 0rem;
	padding-right: 1.4rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrow-right_black.svg');
	background-position: 94% 48%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-size: 0.8rem;
	font-weight: 600;
	text-decoration: none;
}

.view-all-flights-2:hover {
	opacity: 0.7;
	-webkit-transform: translate(4px, 0px);
	-ms-transform: translate(4px, 0px);
	transform: translate(4px, 0px);
}

.view-all-flights-2.right {
	position: relative;
	margin-right: 0.75rem;
}

.dropdown-toggle-2 {
	padding: 9px 1.15rem 0.5rem 0.5rem;
	font-weight: 400;
}

.translate-wrapper {
	display: none;
	height: 100%;
	padding-bottom: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.8rem;
	line-height: 1.4rem;
	font-weight: 500;
}

.language-divider {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: rgba(0, 0, 0, 0.25);
}

.utility-divider {
	width: 2px;
	height: 65%;
	margin-right: 1rem;
	margin-bottom: 0.375rem;
	margin-left: 1rem;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.slidenav-corp {
	left: 4vw;
	right: auto;
	bottom: 0.75rem;
	display: block;
	width: 100px;
	height: 2rem;
	margin-right: auto;
	margin-left: 5rem;
	padding-top: 13px;
	font-size: 0.65rem;
	text-align: left;
}

.promo-arrow-link-corp {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: #4578bc;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.promo-arrow-link-corp:hover {
	background-color: #2a5ea3;
}

.promo-slide-item-wrapper-corporate {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: #fff;
}

.language-link {
	width: 72px;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	color: #5e5e5f;
	font-weight: 500;
	text-align: center;
}

.greek {
	display: block;
	font-weight: 500;
	letter-spacing: 0rem;
}

.english {
	display: none;
	font-weight: 400;
	letter-spacing: 0rem;
}

.phone-link {
	color: #000;
	text-decoration: none;
}

.find-flight-tool {
	position: absolute;
	top: 116px;
	right: 0px;
	width: 535px;
	padding: 24px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: #fff;
	font-family: 'Encode Sans', sans-serif;
	-webkit-transition: top 100ms linear;
	transition: top 100ms linear;
}

.find-flight-view-all-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0rem 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 0px;
}

.flight-search-image {
	display: none;
	width: 27px;
	height: 27px;
	margin-right: 0.5rem;
}

.flight-search-dd-widget-2 {
	display: block;
	margin-right: 0px;
	margin-left: 0px;
}

.view-all-inner-wrap {
	display: inline-block;
	width: 50%;
	height: 0px;
}

.view-all-inner-wrap.right-view-all {
	width: 50%;
	padding-left: 30px;
}

.find-flight-outer-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #fff;
}

.find-flight-dd-toggle {
	display: inline-block;
	width: 134px;
	margin-right: 0px;
	padding: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 0px 4px 4px 0px;
	background-color: #4578bc;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	-webkit-appearance: none;
}

.find-flight-dd-toggle:hover {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #2a5ea3;
}

.find-flight-dd-toggle.w--current {
	background-color: transparent;
	color: #b69b11;
	cursor: pointer;
}

.find-flight-dd-toggle.w--open {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.flight-results-outer-wrap {
	display: block;
	background: #fff;
	height: 0;
}

.inside-table-text-2 {
	width: 100%;
}

.view-all-flights {
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	margin-left: 0rem;
	padding-right: 1.4rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrow-right_034a94.svg');
	background-position: 94% 48%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-size: 0.8rem;
	font-weight: 600;
	text-decoration: none;
}

.view-all-flights:hover {
	opacity: 1;
	-webkit-transform: translate(7px, 0px);
	-ms-transform: translate(7px, 0px);
	transform: translate(7px, 0px);
	color: #4578bc;
}

.view-all-flights.right {
	position: relative;
}

.find-flight-form-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0px;
	float: right;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.flight-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.45rem 0.5rem 0.45rem 0.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px none #c5c2c2;
	font-size: 0.83rem;
	line-height: 1.25rem;
}

.flight-row.loading img {
	margin: 0 auto;
}

.flight-row.white-row {
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.flight-row.tint-row {
	background-color: #e6eff5;
	width: 100%;
}

.find-flight-search-results-wrap {
	overflow: auto;
	width: 100%;
	max-height: 163px;
	margin-top: 0rem;
	background: #fff;
	height: 0px;
}

.find-flight-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.find-flight-tool.loaded .find-flight-form {
	display: flex;
}

.find-flight-dd {
	position: absolute;
	left: 0;
	top: 59px;
	right: 0px;
	z-index: 1002;
	width: 535px;
	height: 0px;
	padding-top: 0rem;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
	opacity: 0;
}

.find-flight-dd.w--open {
	top: 100%;
	right: -24px;
	width: 472px;
	padding-top: 1.5rem;
	background-color: #fff;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.flight-search-image-2 {
	width: 27px;
	height: 27px;
	margin-right: 0.5rem;
}

.mini-find-flight-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.mini-find-flight-dd {
	position: absolute;
	left: 0px;
	top: 52px;
	right: 0px;
	z-index: 1002;
	width: 472px;
	padding-top: 0rem;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.mini-find-flight-dd.w--open {
	top: 100%;
	right: -24px;
	width: 472px;
	padding-top: 1.5rem;
	background-color: #fff;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.mini-find-flight-view-all-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0rem 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.mini-find-flight-form-block {
	position: static;
	display: block;
	width: 100%;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	padding-right: 1rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: #fff;
}

.flight-search-dd-toggle-3 {
	display: inline-block;
	margin-right: 0px;
	padding: 0.25rem 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-radius: 4px;
	background-color: #4578bc;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-align: center;
	cursor: pointer;
}

.flight-search-dd-toggle-3:hover {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #2a5ea3;
}

.flight-search-dd-toggle-3.w--current {
	background-color: transparent;
	color: #b69b11;
	cursor: pointer;
}

.flight-search-dd-toggle-3.w--open {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.mini-flight-results-outer-wrap {
	display: none;
	padding-top: 1.5rem;
}

.mini-find-flight-widget {
	position: absolute;
	right: 0px;
	bottom: 5px;
	z-index: 1000;
	display: none;
	margin-right: 0px;
	margin-left: 0px;
}

.search-close {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-left: 0.5rem;
	padding: 0.25rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0.5;
	font-size: 0.8rem;
	text-decoration: none;
	text-transform: uppercase;
}

.search-close:hover {
	opacity: 1;
}

.mobile-search-field {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
	border: 0px none transparent;
}

.mobile-search-field.search-cover-field {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.close-container {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	display: none;
	width: 100%;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.25rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.search-session-message {
	height: 0px;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
	opacity: 0;
	color: transparent;
}

.close-link {
	position: relative;
	right: 7rem;
	margin-right: 0px;
	margin-bottom: 0.75rem;
	margin-left: 0.5rem;
	padding: 0.25rem;
	float: right;
	opacity: 0.5;
	text-decoration: none;
}

.close-link:hover {
	opacity: 1;
}

.close-image {
	display: block;
}

.search-container-cover {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20000;
	display: none;
	overflow: hidden;
	width: 100%;
	height: 0px;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.85);
}

.close-x-wrapper {
	width: 2rem;
	height: 2rem;
	float: left;
}

.close-x-wrapper:hover {
	opacity: 0.8;
}

.text-block-3 {
	display: none;
	margin-left: 0.25rem;
	color: #fff;
}

.button-5 {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 1rem;
	border-radius: 2px;
	background-color: #000;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.button-5:hover {
	background-color: #007aef;
}

.button-5.search-button {
	width: 30%;
	height: 2.5rem;
	min-height: 2.5rem;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 0.38rem 1rem;
	float: left;
}

.button-5.search-button.search-cover-button {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background-color: #8ba0cd;
	color: #000;
	font-weight: 300;
	text-transform: uppercase;
}

.button-5.search-button.search-cover-button:hover {
	background-color: #a7bcd6;
}

.mobile-search-form {
	display: block;
	border-style: none;
}

.mobile-search-form.search-cover-form {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.mobile-search-form-wrapper {
	width: 50%;
	margin-bottom: 0px;
	padding: 1rem;
	float: right;
	background-color: rgba(0, 0, 0, 0.5);
}

.mobile-search-form-wrapper.search-cover-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 640px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	float: none;
	background-color: transparent;
}

.megamenu-section-head {
	display: block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 6px 10px;
	color: #034a94;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-transform: uppercase;
}

.exch-form-label {
	margin-bottom: 4px;
	margin-left: 2px;
	color: #000;
	font-size: 0.65rem;
	line-height: 0.7rem;
	font-weight: 400;
	text-transform: uppercase;
}

.search-text-field-3 {
	width: 140px;
	margin-bottom: 0px;
	padding-right: 40px;
	padding-bottom: 8px;
	padding-left: 0.25rem;
	border-style: none;
	background-color: transparent;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.8rem;
	line-height: 1.4rem;
	border: 0px;
}

.search-text-field-3:focus {
	width: 175px;
	box-shadow: 0 1px 0 0 #8f9296;
}

.search-text-field-3::-webkit-input-placeholder {
	color: #000;
	text-align: left;
}

.search-text-field-3:-ms-input-placeholder {
	color: #000;
	text-align: left;
}

.search-text-field-3::placeholder {
	color: #000;
	text-align: left;
}

select.exchange-dd-field {
	height: 20px;
	margin-bottom: 11px;
	padding: 0px 2px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-size: 0.75rem;
	line-height: 20px;
}

.weather-wrap-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: 0.125rem;
	padding-bottom: 7px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	color: #797676;
}

.exchange-rate-form-block {
	width: 122px;
	margin-bottom: 0px;
}

.exchange-dd {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #797676;
}

.temperature-2 {
	margin-bottom: 4px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #5e5e5f;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
}

.language-link-2 {
	width: 72px;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	color: #5e5e5f;
	line-height: 1.3rem;
	font-weight: 500;
	text-align: center;
}

.mobile-search-button {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 40px;
	height: 38px;
	border-radius: 4px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk.svg');
	background-position: 50% 45%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	opacity: 0.6;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.mobile-search-button:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk_4578bc.svg');
	background-size: 16px 16px;
	opacity: 1;
}

.upper-divider {
	width: 2px;
	height: 75%;
	margin-right: 1rem;
	margin-bottom: 0.375rem;
	margin-left: 1rem;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.upper-divider.div-1 {
	margin-left: 0.35rem;
}

.upper-divider.div-3 {
	margin-right: 0.45rem;
}

.language-toggle {
	display: block;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #474747;
	font-size: 0.75rem;
	font-weight: 700;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.language-toggle:hover {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #474747;
}

.search-button-3 {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 35px;
	height: 38px;
	padding-right: 0px;
	border-radius: 4px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk.svg');
	background-position: 5px 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	opacity: 0.6;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-button-3:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk_4578bc.svg');
	background-size: 16px 16px;
	opacity: 1;
}

.alert-close-2 {
	position: absolute;
	top: 8px;
	right: 0px;
	width: 60px;
	height: 60px;
	margin-right: 1rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/close_white.svg');
	background-position: 50% 50%;
	background-size: 28px 28px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.alert-close-2:hover {
	opacity: 0.5;
}

.column-2 {
	padding-right: 20px;
	padding-left: 0px;
}

.alert-heading-2 {
	margin-top: 0rem;
	font-family: 'Encode Sans', sans-serif;
	color: #fff;
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 600;
}

.alert-paragraph-2 {
	margin-top: 16px;
	color: #fff;
	font-size: 1.1rem;
}

.button-6 {
	display: inline-block;
	height: 44px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.button-6:hover {
	background-color: #033466;
}

.button-6.alert-btn {
	height: auto;
	margin-bottom: 0rem;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	font-size: 0.88rem;
}

.button-6.alert-btn:hover {
	background-color: #2a5ea3;
}

.alert-wrap {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 2.25rem 4rem 2.5rem 3rem;
	background-color: #033466;
	display: none;
}

.alert-wrap a {
	color: #fff;
}


.alert-and-header-wrap {
	position: fixed;
	z-index: 10000;
	width: 100%;
}

@media (max-width: 767px) {
	.alert-and-header-wrap {
		z-index: 999;
		position: relative;
	}
}

.page-wrapper,
.inside-page-wrapper {
	padding-top: 116px;
}

@media (max-width: 991px) {
	.page-wrapper,
	.inside-page-wrapper {
		padding-top: 199px;
	}
}

@media (max-width: 767px) {
	.page-wrapper,
	.inside-page-wrapper {
		padding-top: 83px;
	}
}

.right-column-styles-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.right-column-link {
	border-bottom: 1px solid #629edc;
	color: #034a94;
	text-decoration: none;
}

.right-column-link:hover {
	border-bottom: 1px solid transparent;
}

.right-col-item-wrap {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-content-inner-wrap {
	display: flex;
	width: 100%;
}

.main-content {
	display: block;
	width: calc(100% - 20% - 0.75px - 3vw);
	padding-top: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.main-content.only-right {
	width: 100%;
}

.main-content.no-sidebars {
	display: block;
	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

.inside-content-sect {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #d6dce0;
}

.inside-page-banner-wrap {
	width: 100%;
	height: 450px;
	padding-right: 8rem;
	padding-left: 8rem;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-wrap.inside-banner-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-position: 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.content-outer-wrap {
	position: relative;
	z-index: 2;
	display: block;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem 4vw 4rem;
	border-top: 8px solid #629edc;
	background-color: #fff;
}

.main-content-outer-wrap {
	display: block;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.session-message-ul {
	margin-bottom: 0rem;
}

.ordered-list {
	padding-left: 20px;
}

.left-nav-section-title-link {
	display: block;
	padding-top: 0.25rem;
	padding-right: 0px;
	padding-bottom: 0.4rem;
	font-family: 'Encode Sans', sans-serif;
	color: #4578bc;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: 250px;
	min-height: 300px;
	min-width: 200px;
	margin-right: 3vw;
	padding-top: 2rem;
	padding-right: 0.75px;
	padding-bottom: 4rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.left-nav-link-2 {
	display: block;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #000;
	font-size: 0.83rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-link-2:hover {
	padding-right: 0rem;
	padding-left: 0.63rem;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #2a5ea3;
}

.left-nav-link-2.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.left-nav-link-2.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-2.grandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	font-size: 0.93rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-2.grandchild-link.w--current {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
}

.left-nav-link-2.greatgrandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-2.greatgrandchild-link:hover {
	box-shadow: none;
}

.left-nav-link-2.greatgrandchild-link.w--current {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
}

.left-nav-wrapper {
	margin-right: 1rem;
	margin-left: 0rem;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.div-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.logo-home-link {
	display: block;
	width: 150px;
	margin-right: 3rem;
	margin-left: 0vw;
	padding: 0.5rem 0rem 0rem;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.field-label {
	font-weight: 500;
}

.highlight {
	background-color: #f8ff5a;
	font-family: inherit;
}

html.w-mod-js *[data-ix="slide-reveal"] {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
}

html.w-mod-js *[data-ix="slide-header-reveal"] {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
}

html.w-mod-js *[data-ix="slide-caption-reveal"] {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
}

html.w-mod-js *[data-ix="insta-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="insta-load-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="insta-load-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="row-2-cb-1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="row-2-cb2-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="cb2-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="feature-slide-stage"] {
	-webkit-transform: scale(0.9400000000000006, 0.9400000000000006);
	-ms-transform: scale(0.9400000000000006, 0.9400000000000006);
	transform: scale(0.9400000000000006, 0.9400000000000006);
}

html.w-mod-js *[data-ix="feature-image"] {
	opacity: 0.5;
	-webkit-transform: scale(1.5, 1.5);
	-ms-transform: scale(1.5, 1.5);
	transform: scale(1.5, 1.5);
}

html.w-mod-js *[data-ix="zoom-in-slide"] {
	opacity: 0;
	-webkit-transform: translate(0px, -20px) scale(0.5, 0.5);
	-ms-transform: translate(0px, -20px) scale(0.5, 0.5);
	transform: translate(0px, -20px) scale(0.5, 0.5);
}

html.w-mod-js *[data-ix="hide-location-options"] {
	display: none;
}

html.w-mod-js *[data-ix="less-options"] {
	display: none;
}

html.w-mod-js *[data-ix="slide-caption-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="cb1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-2-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-3-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-4-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="lower-heading-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-4"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-5"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="feature-text"] {
	opacity: 0;
	-webkit-transform: translate(-40px, 0px);
	-ms-transform: translate(-40px, 0px);
	transform: translate(-40px, 0px);
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="minislideshow-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="intro-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="left-nav-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 120px);
	-ms-transform: translate(0px, 120px);
	transform: translate(0px, 120px);
}

/* tablesaw */
.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
	width: 100%;
}

.tablesaw-cell-content {
	width: 100%;
	max-width: none;
}

.tablesaw-stack tbody td,
.tablesaw-stack tbody th {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	border-bottom: 0;
	text-align: left;
}

.tablesaw-stack tbody td:nth-last-child(1) {
	border-bottom: 1px solid #ccc;
}
/* end of tablesaw */

@media (max-width: 991px) {
	h1 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 1.8rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	h3 {
		font-size: 1.4rem;
		line-height: 2.1rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	h5 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	h6 {
		font-size: 1.1rem;
		font-weight: 500;
	}

	.container {
		max-width: 768px;
	}

	.container.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.signoff-container {
		padding-top: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		display: block;
	}

	.container.inside-content-container {
		max-width: none;
		padding-top: 1rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container.parking-container {
		margin-left: 0vw;
		flex-direction: column;
		width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container.promo-and-cb-container {
		max-width: none;
	}

	.container.page-title-container {
		padding-left: 2rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 0rem;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		position: static;
		display: inline-block;
		width: 100%;
	}

	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 0.75rem 1.25rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		background-color: transparent;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(69, 120, 188, 0.35);
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children {
		width: 100%;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children:hover {
		background-color: #033466;
		background-image: none;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children.w--current {
		background-color: rgba(69, 120, 188, 0.35);
		box-shadow: inset 4px 0 0 0 #4578bc;
		color: #000;
	}

	.hero-rotator-section {
		height: auto;
		max-height: none;
		min-height: 0px;
	}

	.hero-rotator {
		width: auto;
		height: auto;
	}

	.slideshow-arrow.left-arrow {
		left: 2rem;
		overflow: hidden;
	}

	.slideshow-arrow.right-arrow {
		left: 2rem;
	}

	.slideshow-arrow.right-arrow {
		left: 2rem;
	}

	.slideshow-arrow.left-arrow {
		left: 2rem;
		overflow: hidden;
	}

	.slideshow-arrow.right-slide-arrow {
		left: 2rem;
	}

	.slideshow-arrow.left-slide-arrow {
		left: 2rem;
		overflow: hidden;
	}

	.slidenav {
		left: 2rem;
		width: 200px;
	}

	.arrow-icon {
		left: 0px;
		right: 0px;
		bottom: 0px;
		color: hsla(0, 0%, 100%, 0.75);
	}

	.home-content-section.hcs-row-2 {
		padding-top: 3vw;
		padding-bottom: 4vw;
	}

	.signoff-wrapper {
		margin-top: 0.75rem;
	}

	.copyright-paragraph {
		margin-right: 0px;
	}

	.menu-icon {
		display: block;
		width: 40px;
		height: 40px;
		margin-right: auto;
		margin-left: auto;
		padding: 3px 0px 0px;
		font-size: 2.15rem;
		line-height: 2.15rem;
		text-align: center;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid rgba(0, 0, 0, 0.2);
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(69, 120, 188, 0.35);
	}

	.footer-paragraph {
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.footer-nav-list {
		-webkit-column-count: 1;
		column-count: 1;
	}

	.footer-nav-list-item {
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.copyright-wrapper {
		margin-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	.locations-options-wrapper {
		display: block;
	}

	.more-options-button {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
	}

	.privacy-terms-wrapper {
		margin-bottom: 0.75rem;
	}

	.copyright-privacy-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.less-options-button {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
	}

	.hero-image-wrapper {
		width: 100%;
		height: 400px;
	}

	.hero-image-wrapper.hero-1 {
		width: 100%;
	}

	.feature-card-heading {
		font-size: 1.7rem;
	}

	.upper-left-wrap {
		display: block;
	}

	.cb-feature-heading {
		font-size: 2.25rem;
		line-height: 2.7rem;
	}

	.feature-cb-paragraph {
		font-size: 1.15rem;
		line-height: 2rem;
	}

	.footer-lower-parent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.nav-section-wrap {
		display: none;
	}

	.header-right-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.alert-wrapper {
		padding-top: 3rem;
	}

	.alert-close {
		top: 0px;
		margin-right: 0rem;
	}

	.footer-cb.footer-cb-1 {
		width: 50%;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.footer-cb.footer-cb-2 {
		width: 50%;
		margin-top: 1rem;
	}

	.footer-cb.footer-cb-2 {
		width: 50%;
		margin-top: 0rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.footer-cb.footer-cb-4 {
		width: 50%;
		margin-top: 1rem;
	}

	.footer-cb.footer-cb-3 {
		width: 50%;
		margin-top: 1rem;
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
	}

	.inside-flight-schedule-wrapper {
		padding-bottom: 3rem;
	}

	.inside-tab-1-arrivals {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.inside-flight-form-text-field {
		font-size: 0.85rem;
	}

	.inside-table-cell.flight-number-cell {
		width: 28%;
	}

	.inside-table-cell.city-to-from-cell {
		width: 28%;
	}

	.inside-table-cell.time-cell {
		width: 12%;
	}

	.inside-table-cell.status-cell {
		width: 20%;
	}

	.inside-flight-search-wrap {
		width: 100%;
	}

	.inside-flight-dd-fields-wrap {
		width: 100%;
		margin-top: 1rem;
	}

	.inside-tab-2-departures {
		padding-right: 1rem;
		padding-bottom: 1.5rem;
		padding-left: 1rem;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.insta-tile-link {
		margin-bottom: 30px;
	}

	.insta-tile-link.insta-1 {
		margin-bottom: 0px;
	}

	.insta-tile-link.insta-2 {
		margin-bottom: 0px;
	}

	.insta-tile-link.insta-3 {
		margin-bottom: 0px;
	}

	.insta-tile-link.insta-4 {
		margin-bottom: 0px;
	}

	.slider-caption {
		position: static;
		display: block;
		width: 100%;
		height: auto;
		max-width: none;
		min-width: 0px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 2rem;
		padding-bottom: 3.5rem;
		padding-left: 2rem;
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-inner-wrap {
		position: static;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slideshow-header {
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.slideshow-caption {
		width: 100%;
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.insta-inner-wrap {
		width: 384px;
		height: 384px;
	}

	.instagram-wrapper {
		height: 384px;
	}

	.content-box.cb-2 {
		width: 100%;
		min-height: 0px;
		height: auto;
	}

	.content-box.cb-2.corp-cb2 {
		min-height: 300px;
	}

	.content-box.cb-1.parking-text {
		width: 100%;
		padding: 0 0 2rem 0;
	}

	.content-box.callout-cb {
		max-width: 768px;
		padding: 2rem;
	}

	.content-box.promo-cb {
		min-height: 0px;
		padding-top: 0rem;
	}

	.parking-form-header {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.insta-small-image-wrap {
		width: 192px;
		height: 192px;
	}

	.promo-slide-item-wrapper {
		background-color: transparent;
		width: 100%;
		height: 100%;
	}

	.promo-link-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.promo-item-outer-text-wrap {
		width: 100%;
		height: auto;
		padding-right: 5rem;
		padding-bottom: 3rem;
		padding-left: 5rem;
	}

	.promo-arrow-link {
		width: 45px;
		height: 45px;
		margin-right: 15px;
		margin-left: 15px;
	}

	.promo-image {
		width: 100%;
		height: 400px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.promo-slider {
		height: auto;
	}

	.promo-mask {
		display: block;
		max-width: 703px;
		margin-right: auto;
		margin-left: auto;
		background-color: #f4f5f0;
	}

	.promo-slide {
		margin-right: 0%;
		margin-left: 0%;
	}

	.slide-nav {
		bottom: 0px;
		max-width: 703px;
	}

	.header-search-form {
		position: relative;
	}

	.mobile-menu-toggle-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 44px;
		height: 44px;
		margin-right: 3vw;
		padding: 0px;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 4px;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #000;
	}

	.mobile-menu-toggle-2:hover {
		background-color: transparent;
		color: #4578bc;
	}

	.mobile-menu-toggle-2.w--open {
		background-color: transparent;
		color: #000;
		float: none;
	}

	.header-sect {
		padding-top: 0.25rem;
		padding-right: 0vw;
		padding-bottom: 9px;
		background-color: #fff;
	}

	.nav-bar-widget-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0.65rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.search-go-button.flight-quick-search-button {
		position: absolute;
		right: 0px;
		width: 4rem;
		height: 4rem;
		font-size: 1rem;
	}

	.search-text-field-2 {
		width: 150px;
		padding-bottom: 6px;
	}

	.search-text-field-2:focus {
		width: 150px;
	}

	.subnav-outer-wrap {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		width: 100%;
		margin-top: 58px;
		padding-top: 8px;
		padding-left: 8px;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-color: #737373;
		box-shadow: -4px 4px 15px 0 rgba(0, 0, 0, 0.3);
		color: #fff;
	}

	.flight-search-form-block {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.logo-wrapper {
		width: auto;
		margin-bottom: 0.375rem;
	}

	.header-right-upper-wrap {
		width: auto;
		height: auto;
		padding-top: 0rem;
		padding-right: 3vw;
	}

	.flight-search-dd-icon {
		position: absolute;
	}

	.search-dd {
		z-index: 1002;
	}

	.flight-quick-search-results-wrap {
		max-height: 160px;
	}

	.category-nav-link-2 {
		display: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.topnav-link-2 {
		padding-left: 1.3rem;
		background-position: 0px 2px;
		background-size: 16px 16px;
		font-size: 0.75rem;
	}

	.topnav-link-2:hover {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/person-roundhead-blk-tall.svg');
		background-position: 0px 2px;
		background-size: 16px 16px;
		background-repeat: no-repeat;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.topnav-link-2.picker-link:hover {
		background-image: none;
	}

	.header-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.translate-wrap {
		margin-bottom: 0.4375rem;
	}

	.form-field.flight-search-field {
		font-size: 1rem;
	}

	.form-field.flight-search-field {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-radius: 4px 0px 0px 4px;
	}

	.form-field.mini-flight-search-field {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.flight-search-dd-toggle {
		background-image: none;
	}

	.flight-search-dd-toggle:hover {
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.flight-search-dd-toggle.w--current {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #b69b11;
		color: #fff;
	}

	.quick-search-text-wrap {
		padding-right: 1.5rem;
	}

	.flight-search-dd-widget {
		position: absolute;
		right: 211px;
		bottom: 14px;
	}

	.main-nav-text {
		color: #fff;
		font-family: inherit;
	}

	.upper-right-wrap {
		padding-top: 6px;
	}

	.table-row {
		padding-left: 1rem;
	}

	.view-all-flights-2 {
		font-size: 0.85rem;
	}

	.utility-divider {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.slidenav-corp {
		width: 200px;
	}

	.promo-arrow-link-corp {
		width: 45px;
		height: 45px;
	}

	.greek {
		font-weight: 500;
	}

	.english {
		font-weight: 500;
	}

	.find-flight-tool {
		top: 113px;
		z-index: 500;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.3);
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.find-flight-dd-toggle {
		width: 134px;
		border-radius: 0px 4px 4px 0px;
	}

	.find-flight-dd-toggle:hover {
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.find-flight-dd-toggle.w--current {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #b69b11;
		color: #fff;
	}

	.find-flight-dd-toggle.w--open {
		width: 134px;
	}

	.view-all-flights {
		font-size: 0.85rem;
	}

	.view-all-flights:hover {
		padding-right: 1.4rem;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.flight-row {
		padding-left: 1rem;
	}

	.find-flight-search-results-wrap {
		max-height: 160px;
	}

	.find-flight-form {
		position: relative;
		width: 100%;
	}

	.find-flight-dd {
		display: block;
		margin-right: auto;
		margin-left: auto;
		box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
		left: 0;
	}

	.find-flight-dd.w--open {
		right: 0px;
		padding-top: 0rem;
		box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05), -1px 1px 3px 0 rgba(0, 0, 0, 0.15), 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
	}

	.mini-find-flight-form {
		position: relative;
		width: 100%;
	}

	.mini-find-flight-dd {
		display: block;
		margin-right: auto;
		margin-left: auto;
		box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
	}

	.mini-find-flight-dd.w--open {
		right: 0px;
		padding-top: 0rem;
		box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05), -1px 1px 3px 0 rgba(0, 0, 0, 0.15), 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
	}

	.mini-find-flight-form-block {
		width: 318px;
	}

	.flight-search-dd-toggle-3 {
		background-image: none;
	}

	.flight-search-dd-toggle-3:hover {
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.flight-search-dd-toggle-3.w--current {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #b69b11;
		color: #fff;
	}

	.mini-find-flight-widget {
		position: absolute;
		right: 211px;
		bottom: 14px;
	}

	.search-close {
		right: 6rem;
	}

	.close-container {
		max-width: 991px;
		margin-right: auto;
		margin-left: auto;
	}

	.close-link {
		right: 6rem;
		padding-top: 0.5rem;
	}

	.mobile-search-form-wrapper.search-cover-wrapper {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.search-text-field-3 {
		width: 150px;
		padding-bottom: 6px;
	}

	.search-text-field-3:focus {
		width: 150px;
	}

	.exchange-dd-field {
		margin-bottom: 5px;
	}

	.exchange-rate-form-block {
		margin-top: -7px;
	}

	.upper-divider {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.language-toggle {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
		border-bottom-style: none;
	}

	.language-toggle:hover {
		box-shadow: inset 0 -2px 0 0 #629edc;
	}

	.alert-close-2 {
		top: 8px;
	}

	.column-3 {
		padding-left: 0px;
	}

	.alert-heading-2 {
		margin-bottom: 0.5rem;
		font-size: 1.75rem;
		line-height: 2.75rem;
	}

	.alert-paragraph-2 {
		margin-top: 0px;
	}

	.alert-wrap {
		padding-top: 1.5rem;
		padding-right: 4.5rem;
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
	}

	.inside-page-banner-wrap {
		height: 250px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 400px;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.main-content-outer-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.left-nav-col {
		display: none;
	}
}

@media (max-width: 767px) {
	h1 {
		padding-top: 0.65rem;
		font-size: 1.7rem;
		line-height: 2.4rem;
	}

	h2 {
		margin-top: 1.25rem;
	}

	h3 {
		margin-bottom: 0.5rem;
		font-size: 1.35rem;
		line-height: 2.1rem;
	}

	h6 {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	blockquote {
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.button.tertiary.language-toggle {
		font-size: 0.75rem;
		line-height: 1.2rem;
	}

	.button.tertiary.language-toggle {
		font-size: 0.75rem;
		line-height: 1.2rem;
	}

	.button.promo-fake-button {
		margin-bottom: 0rem;
	}

	.container {
		max-width: 550px;
	}

	.container.footer-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.inside-content-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.parking-container {
		width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.promo-and-cb-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.page-title-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.logo-link {
		width: 140px;
		margin-left: 2rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 1rem;
	}

	.hero-rotator-section {
		margin-bottom: 1rem;
	}

	.slideshow-arrow.left-arrow {
		left: 2rem;
	}

	.slideshow-arrow.right-arrow {
		left: 2rem;
	}

	.slideshow-arrow.right-arrow {
		left: 2rem;
	}

	.slideshow-arrow.left-arrow {
		left: 2rem;
	}

	.slideshow-arrow.right-slide-arrow {
		left: 2rem;
	}

	.slideshow-arrow.left-slide-arrow {
		left: 2rem;
	}

	.slidenav {
		left: 2rem;
	}

	.arrow-icon.right-arrow-icon {
		background-image: -webkit-linear-gradient(270deg, transparent, transparent);
		background-image: linear-gradient(180deg, transparent, transparent);
	}

	.home-content-section {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.home-content-section.hcs-row-1 {
		padding-top: 2.5rem;
		padding-bottom: 4rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.home-content-section.hcs-row-2 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.home-content-section.hcs-row-4 {
		padding: 4rem 4.375rem;
	}

	.home-content-section.hcs-row-4-corp {
		padding: 4rem 2rem;
	}

	.signoff-wrapper {
		margin-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.mobile-menu-arrow {
		width: 16%;
	}

	.footer-nav-list-item {
		margin-bottom: 0.3rem;
	}

	.privacy-terms-wrapper {
		margin-bottom: 0.75rem;
	}

	.hero-image-wrapper {
		height: 300px;
	}

	.cb-feature-heading {
		font-size: 2.25rem;
		line-height: 2.6rem;
	}

	.inside-table-text {
		line-height: 1.3rem;
	}

	.inside-table-text.inside-table-header-centered {
		font-size: 0.9rem;
		line-height: 1.3rem;
	}

	.inside-table-text.inside-table-header-tablesaw {
		color: #034a94;
		font-size: 0.9rem;
		line-height: 1.3rem;
	}

	.header-right-wrap {
		padding-top: 1rem;
	}

	.header-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.alert-wrapper {
		padding-left: 2rem;
	}

	.alert-heading {
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.alert-paragraph {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.column {
		padding-top: 0rem;
	}

	.footer-cb {
		width: 100%;
	}

	.footer-cb.footer-cb-1 {
		width: 50%;
	}

	.footer-cb.footer-cb-2 {
		width: 50%;
	}

	.footer-cb.footer-cb-2 {
		width: 50%;
	}

	.footer-cb.footer-cb-4 {
		width: 50%;
	}

	.footer-cb.footer-cb-3 {
		width: 50%;
		padding-right: 0.25rem;
	}

	.inside-flight-schedule-wrapper {
		padding-bottom: 3rem;
	}

	.inside-table-row {
		min-height: 36px;
	}

	.inside-table-row.inside-header-row-tablesaw {
		display: none;
		width: 15%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom-style: none;
		background-color: transparent;
	}

	.inside-table-cell.flight-number-cell {
		width: 26%;
	}

	.inside-table-cell.city-to-from-cell {
		width: 26%;
	}

	.inside-table-cell.more-info-cell {
		width: 10%;
	}

	.inside-table-cell.status-cell {
		width: 26%;
	}

	.flight-status-dd-field {
		padding-right: 6px;
		padding-left: 6px;
	}

	.inside-tab-content {
		height: auto;
	}

	.inside-table-status-text.departed {
		line-height: 1.3rem;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.inside-table-text-tablesaw {
		line-height: 1.3rem;
	}

	.inside-table-status-text-tablesaw.departed {
		line-height: 1.3rem;
	}

	.insta-tile-link {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.slideshow-header {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		font-size: 2.15rem;
		line-height: 2.65rem;
	}

	.slideshow-caption {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.insta-inner-wrap {
		width: 416px;
		height: 416px;
	}

	.instagram-wrapper {
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-header-wrap {
		padding-right: 3.25rem;
		padding-left: 3.25rem;
	}

	.content-box.cb-2 {
		width: 100%;
		margin-left: 0vw;
	}

	.content-box.cb-1.parking-text {
		width: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0rem 0rem 2rem 0vw;
	}

	.content-box.callout-cb {
		min-width: 416px;
	}

	.content-box.promo-cb {
		width: 100%;
		padding-top: 0rem;
	}

	.insta-small-image-wrap {
		width: 208px;
		height: 208px;
	}

	.promo-slide-item-wrapper {
		background-color: #ffffff;
		width: 100%;
		height: 100%;
	}

	.promo-link-wrapper {
		border-style: none;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.1);
	}

	.promo-item-heading-copy {
		margin-bottom: 0.7rem;
		font-size: 1.75rem;
		line-height: 2.45rem;
	}

	.promo-image {
		width: 100%;
		height: 325px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.promo-image.promo-image-3 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.promo-slider {
		height: auto;
	}

	.promo-mask {
		display: block;
		max-width: 703px;
		margin-right: auto;
		margin-left: auto;
		background-color: #f4f5f0;
	}

	.slide-nav {
		bottom: 0px;
		max-width: 703px;
	}

	.promo-paragraph {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.header-search-form {
		width: 100%;
	}

	.mobile-menu-toggle-2:hover {
		background-image: none;
	}

	.header-sect {
		padding-bottom: 0rem;
		padding-left: 0rem;
		background-color: #fff;
	}

	.search-text-field-2:focus {
		width: 150px;
		border-right-style: none;
	}

	.weather-wrap {
		padding-top: 0.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.subnav-outer-wrap {
		margin-top: 68px;
	}

	.flight-search-form-block {
		padding-top: 0.5rem;
		padding-right: 2rem;
		padding-left: 2rem;
		float: none;
		border-top-style: none;
	}

	.logo-wrapper {
		top: 0.25rem;
		width: auto;
	}

	.header-right-upper-wrap {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-right: 3vw;
		padding-left: 3vw;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.flight-search-dd-icon {
		position: absolute;
		float: right;
	}

	.flight-quick-search-results-wrap {
		max-height: 164px;
	}

	.flight-info-link {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.header-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 40px;
		padding-bottom: 10px;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.translate-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		margin-bottom: 0rem;
	}

	.form-field.flight-search-field {
		width: 100%;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.form-field.flight-search-field {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.form-field.flight-search-field {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.form-field.mini-flight-search-field {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.flight-search-dd-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		border-radius: 0px;
		background-color: #d14300;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		font-size: 1rem;
	}

	.search-outer-wrapper {
		padding-top: 0.25rem;
	}

	.flight-search-dd-widget {
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: 100%;
	}

	.upper-right-wrap {
		width: 100%;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.table-row {
		padding-left: 0.75rem;
	}

	.translate-wrapper {
		padding-top: 0.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.utility-divider {
		height: 100%;
		margin-right: 1vw;
		margin-bottom: 0rem;
		margin-left: 1vw;
	}

	.image {
		margin-right: 0.6rem;
	}

	.greek {
		position: relative;
		top: 2px;
		width: 100%;
	}

	.english {
		width: 100%;
	}

	.find-flight-tool {
		top: 148px;
		-webkit-transition-duration: 250ms;
		transition-duration: 250ms;
	}

	.flight-search-image {
		margin-right: 0.6rem;
	}

	.find-flight-dd-toggle {
		width: 134px;
	}

	.inside-table-text-2 {
		line-height: 1.3rem;
	}

	.flight-row {
		padding-left: 0.75rem;
	}

	.find-flight-search-results-wrap {
		max-height: 164px;
	}

	.find-flight-dd {
		width: 90%;
	}

	.find-flight-dd.w--open {
		right: 0px;
		width: 432px;
	}

	.flight-search-image-2 {
		margin-right: 0.6rem;
	}

	.mini-find-flight-dd {
		width: 432px;
	}

	.mini-find-flight-dd.w--open {
		right: 0px;
		width: 432px;
	}

	.mini-find-flight-form-block {
		width: 298px;
	}

	.flight-search-dd-toggle-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		border-radius: 0px;
		background-color: #d14300;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		font-size: 1rem;
	}

	.mini-find-flight-widget {
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: 100%;
	}

	.mobile-search-field.search-cover-field {
		width: 71%;
	}

	.button-5.search-button.search-cover-button {
		width: 25%;
		font-size: 0.95rem;
	}

	.mobile-search-form-wrapper.search-cover-wrapper {
		width: 400px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.search-text-field-3:focus {
		width: 150px;
		border-right-style: none;
	}

	.weather-wrap-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.exchange-dd {
		padding-top: 0.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.upper-divider {
		height: 100%;
		margin-right: 1vw;
		margin-bottom: 0rem;
		margin-left: 1vw;
	}

	.alert-close-2 {
		top: 0px;
		right: -12px;
		background-size: 24px 24px;
	}

	.column-3 {
		padding-top: 0rem;
	}

	.alert-paragraph-2 {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.alert-wrap {
		padding-left: 2rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 300px;
	}

	.content-outer-wrap {
		padding-right: 1.5rem;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
	}

	.logo-home-link {
		width: 140px;
		margin-left: 2rem;
	}

	#flight-schedule-search-form.inside-flight-form fieldset {
		width: 100%;
	}
}

@media (max-width: 479px) {
	h1 {
		padding-top: 0.65rem;
		font-size: 1.6rem;
		line-height: 2.2rem;
	}

	h2 {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	h3 {
		font-size: 1.3rem;
		line-height: 2rem;
	}

	h4 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	h5 {
		font-size: 1.05rem;
		line-height: 1.65rem;
	}

	p {
		font-size: 0.98rem;
		line-height: 1.65rem;
	}

	blockquote {
		font-size: 1.05rem;
		line-height: 1.75rem;
	}

	.button.tertiary.language-toggle {
		padding-right: 0.3rem;
		padding-left: 0.3rem;
	}

	.button.tertiary.language-toggle {
		padding-right: 0.3rem;
		padding-left: 0.3rem;
	}

	.button.inside-flight-button {
		width: 100%;
		margin-top: 1rem;
	}

	.button.inside-flight-button:hover {
		margin-top: 1rem;
	}

	.container {
		max-width: none;
	}

	.container.footer-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.signoff-container {
		width: 100%;
	}

	.container.inside-content-container {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		background-color: hsla(0, 0%, 100%, 0.9);
	}

	.container.parking-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.page-title-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.logo-link {
		width: 110px;
		margin-left: 1.5rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
	}

	.slideshow-arrow.left-arrow {
		left: 1rem;
	}

	.slideshow-arrow.right-arrow {
		left: 1rem;
	}

	.slideshow-arrow.right-arrow {
		left: 1rem;
	}

	.slideshow-arrow.left-arrow {
		left: 1rem;
	}

	.slideshow-arrow.right-slide-arrow {
		left: 1rem;
	}

	.slideshow-arrow.left-slide-arrow {
		left: 1rem;
	}

	.slidenav {
		left: 1rem;
		background-color: transparent;
	}

	.arrow-icon {
		color: hsla(0, 0%, 100%, 0.85);
		cursor: pointer;
	}

	.arrow-icon.right-arrow-icon {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/rounded-tab-left-wht-50pct.svg');
		background-position: 112% 50%;
		background-size: 130px 40px;
		background-repeat: no-repeat;
	}

	.home-content-section {
		padding-bottom: 4.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.home-content-section.hcs-row-4 {
		padding: 4.5rem 1rem;
	}

	.home-content-section.hcs-row-4-corp {
		padding: 4.5rem 1rem;
	}

	.copyright-paragraph {
		margin-right: 1rem;
		margin-left: 1rem;
		line-height: 1.4rem;
	}

	.signoff-link {
		display: inline-block;
		margin-bottom: 0.25rem;
		line-height: 1.3rem;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.privacy-terms-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.hero-image-wrapper {
		height: 200px;
	}

	.feature-card-heading {
		font-size: 1.5rem;
		line-height: 1.9rem;
	}

	.cb-feature-heading {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.feature-cb-paragraph.white-feature-cb-para {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.footer-header.filler-hack {
		display: none;
	}

	.inside-table-text.date-text {
		font-size: 1.1rem;
	}

	.inside-table-text.inside-table-header-tablesaw {
		font-size: 1rem;
	}

	.header-right-wrap {
		padding-top: 0rem;
	}

	.alert-wrapper {
		padding-right: 2rem;
	}

	.alert-heading {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.footer-cb {
		width: 100%;
	}

	.footer-cb.footer-cb-1 {
		width: 100%;
	}

	.footer-cb.footer-cb-2 {
		width: 100%;
	}

	.footer-cb.footer-cb-2 {
		width: 100%;
		padding-top: 1rem;
	}

	.footer-cb.footer-cb-4 {
		width: 100%;
	}

	.footer-cb.footer-cb-3 {
		width: 100%;
	}

	.inside-flight-tab-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.inside-flight-tab-link {
		width: 47%;
		margin-right: 0.25rem;
		padding-top: 9px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		text-align: center;
	}

	.inside-flight-tab-link.w--current {
		padding-right: 0.5rem;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		text-align: center;
	}

	.inside-flight-tab-link.inside-departures-tab {
		padding-top: 9px;
		padding-right: 0.5rem;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		text-align: center;
	}

	.inside-flight-tab-link.inside-departures-tab.w--current {
		width: 47%;
		padding-left: 0.5rem;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.inside-tab-1-arrivals {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-flight-form-block {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-flight-form {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-flight-form-text-field {
		width: 100%;
		padding-right: 6px;
		padding-left: 6px;
	}

	.inside-table-row.new-day-row {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		border-bottom-style: none;
	}

	.inside-table-row.inside-header-row-tablesaw {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100px;
	}

	.inside-table-cell.time-cell {
		width: 100%;
	}

	.inside-table-cell.inside-lg-cell-tablesaw {
		width: 100%;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.inside-table-cell.inside-lg-cell-tablesaw.status-cell-tablesaw {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.inside-table-cell.inside-small-cell-tablesaw {
		width: 20%;
	}

	.inside-table-cell.inside-small-cell-tablesaw {
		width: 100%;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.more-flight-info-link {
		margin-right: 0px;
		margin-left: 0px;
	}

	.flight-status-tools-wrap {
		border-bottom-style: none;
	}

	.module-flights .inside-flight-dd-fields-wrap .flight-status-dd-field {
		width: 100%;
		margin-bottom: 1rem;
	}

	.inside-flight-search-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-tab-content {
		height: auto;
	}

	.inside-table-status-text.gate-closed {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.inside-flight-dd-fields-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-tab-2-departures {
		padding-right: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.inside-table-row-tablesaw {
		width: 60%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-bottom-style: none;
	}

	.tablesaw-row-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-top: 0.25rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid #cecab7;
	}

	.tablesaw-row-wrap.shaded-tablesaw {
		background-color: #f9f9f9;
		width: 100%;
	}

	.inside-table-text-tablesaw {
		font-size: 1rem;
	}

	.inside-table-status-text-tablesaw.landed {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.inside-table-status-text-tablesaw.on-time {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.inside-table-status-text-tablesaw.delayed {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.inside-table-status-text-tablesaw.cancelled {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.inside-table-status-text-tablesaw.boarding {
		background-color: #ffec62;
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.inside-table-status-text-tablesaw.departed {
		font-size: 1rem;
	}

	.tablesaw-outer-wrap {
		display: block;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.insta-tile-link {
		width: 100%;
	}

	.slider-caption {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 0.925em;
	}

	.slide-caption-inner-wrap {
		padding-bottom: 0rem;
	}

	.slideshow-header {
		font-size: 1.85rem;
		line-height: 2.15rem;
	}

	.slideshow-caption {
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.insta-inner-wrap {
		width: 340px;
		height: 340px;
	}

	.instagram-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-header-wrap {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.content-box.cb-2 {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.content-box.callout-cb {
		min-width: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.content-box.promo-cb {
		padding-top: 0rem;
		padding-bottom: 0rem;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.parking-form-outer-wrap {
		width: 100%;
	}

	.insta-small-image-wrap {
		width: 170px;
		height: 170px;
	}

	.promo-item-outer-text-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.promo-item-heading-copy {
		font-size: 1.5rem;
	}

	.promo-arrow-link {
		top: -8px;
		bottom: 102px;
		width: 40px;
		height: 40px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.promo-image {
		height: 212px;
	}

	.promo-mask {
		display: block;
		max-width: 340px;
		margin-right: auto;
		margin-left: auto;
	}

	.slide-nav {
		max-width: 340px;
	}

	.promo-arrow-icon {
		font-size: 1.25rem;
	}

	.social-media-link-block-2.footer-socmed-link-block {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.mobile-menu-toggle-2 {
		margin-top: 17px;
		margin-right: 1rem;
	}

	.temperature {
		font-size: 0.8rem;
	}

	.search-text-field-2 {
		width: 141px;
	}

	.search-text-field-2:focus {
		width: 141px;
	}

	.subnav-outer-wrap {
		width: 100%;
		margin-top: 76px;
	}

	.weather-icon {
		width: 26px;
		min-width: 26px;
		margin-right: 0.125rem;
	}

	.flight-search-form-block {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.logo-wrapper {
		width: auto;
	}

	.header-right-upper-wrap {
		padding-right: 0vw;
		padding-left: 0vw;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.search-form-block-2 {
		display: none;
	}

	.flight-quick-search-results-wrap {
		max-height: 192px;
	}

	.translate-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		min-width: 62px;
	}

	.search-button-2 {
		position: relative;
		top: 2px;
		right: -6px;
		width: 40px;
		height: 36px;
	}

	.form-field.flight-search-field {
		font-size: 0.875rem;
	}

	.form-field.flight-search-field {
		height: 40px;
		padding-left: 0.5rem;
		font-size: 0.875rem;
	}

	.form-field.flight-search-field::-webkit-input-placeholder {
		font-size: 0.85rem;
	}

	.form-field.flight-search-field:-ms-input-placeholder {
		font-size: 0.85rem;
	}

	.form-field.flight-search-field::placeholder {
		font-size: 0.85rem;
	}

	.form-field.flight-search-field {
		height: 40px;
		padding-left: 0.5rem;
		font-size: 0.875rem;
	}

	.form-field.flight-search-field::-webkit-input-placeholder {
		font-size: 0.85rem;
	}

	.form-field.flight-search-field:-ms-input-placeholder {
		font-size: 0.85rem;
	}

	.form-field.flight-search-field::placeholder {
		font-size: 0.85rem;
	}

	.form-field.mini-flight-search-field {
		height: 40px;
		padding-left: 0.5rem;
		font-size: 0.875rem;
	}

	.form-field.mini-flight-search-field::-webkit-input-placeholder {
		font-size: 0.85rem;
	}

	.form-field.mini-flight-search-field:-ms-input-placeholder {
		font-size: 0.85rem;
	}

	.form-field.mini-flight-search-field::placeholder {
		font-size: 0.85rem;
	}

	.table-cell {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.table-cell.dd-type {
		width: 100%;
	}

	.table-cell.dd-type img.flight-type {
		height: 20px;
		width: 30px;
	}

	.table-cell.dd-time-date {
		width: 100%;
	}

	.table-cell.quick-search-city-flight {
		width: 50%;
	}

	.table-cell.dd-airline {
		width: 100%;
	}

	.table-cell.dd-status {
		width: 100%;
	}

	.table-cell.dd-airport-flight {
		width: 100%;
	}

	.search-outer-wrapper {
		width: 80px;
		height: 42px;
	}

	.upper-right-wrap {
		padding-right: 0.4rem;
	}

	.table-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.flight-info-arrow-link {
		display: none;
	}

	.flight-quick-search-link-wrap-2 {
		padding: 0.75rem 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.view-all-flights-2 {
		font-size: 0.8rem;
	}

	.utility-divider {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.slidenav-corp {
		background-color: transparent;
		font-family: inherit;
	}

	.promo-arrow-link-corp {
		bottom: 50%;
		width: 40px;
		height: 40px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.greek {
		position: relative;
		top: 2px;
		width: 100%;
	}

	.english {
		width: 100%;
	}

	.find-flight-tool {
		top: 132px;
	}

	.find-flight-view-all-wrap {
		padding: 0.75rem 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.flight-search-image {
		display: block;
		width: 20px;
		height: 20px;
		margin-right: 0rem;
	}

	.view-all-inner-wrap {
		width: 100%;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.view-all-inner-wrap.right-view-all {
		width: 100%;
		padding-left: 0px;
	}

	.find-flight-dd-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 40px;
		height: 40px;
		padding: 0.25rem 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.find-flight-dd-toggle.w--open {
		width: 40px;
	}

	.inside-table-text-2 {
		width: auto;
	}

	.inside-table-text-2.second-line {
		margin-left: 0.5rem;
	}

	.view-all-flights {
		margin-top: 0rem;
		margin-bottom: 0rem;
		font-size: 0.8rem;
		line-height: 1.2rem;
	}

	.flight-dd-text-2 {
		display: none;
	}

	.flight-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.find-flight-search-results-wrap {
		max-height: 192px;
	}

	.find-flight-dd {
		top: 73%;
		width: 272px;
	}

	.find-flight-dd.w--open {
		width: 272px;
	}

	.mini-find-flight-dd {
		top: 73%;
		width: 272px;
	}

	.mini-find-flight-dd.w--open {
		width: 272px;
	}

	.mini-find-flight-view-all-wrap {
		padding: 0.75rem 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.mini-find-flight-form-block {
		width: 232px;
	}

	.table-cell.dd-notification {
		flex-basis: unset;
	}

	.table-cell.dd-notification .bt_bizTweet {
		margin-right: 15px;
		margin-top: 5px;
	}

	.search-close {
		right: 5.5rem;
		margin-top: 1rem;
		margin-right: 0.75rem;
		padding-top: 0.25rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		opacity: 0.7;
	}

	.mobile-search-field {
		width: 70%;
	}

	.mobile-search-field.search-cover-field {
		width: 67%;
		height: 40px;
		padding-top: 9px;
	}

	.close-link {
		right: 5.5rem;
		padding-top: 0.25rem;
	}

	.search-container-cover {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.close-x-wrapper {
		width: 1.25rem;
		height: 1.25rem;
	}

	.text-block-3 {
		display: block;
		margin-right: 0.25rem;
		margin-left: 0rem;
		font-size: 0.9rem;
	}

	.button-5.search-button {
		width: 30%;
		min-width: 0px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.9rem;
	}

	.button-5.search-button.search-cover-button {
		position: relative;
		width: 30%;
		height: 40px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: #4578bc;
		background-image: none;
		opacity: 1;
		color: #fff;
		font-size: 0.9rem;
		font-weight: 600;
	}

	.button-5.search-button.search-cover-button:hover {
		background-color: #2a5ea3;
	}

	.mobile-search-form.search-cover-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
	}

	.mobile-search-form-wrapper {
		min-width: 100%;
	}

	.mobile-search-form-wrapper.search-cover-wrapper {
		width: 260px;
		height: 88px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.search-text-field-3 {
		width: 141px;
	}

	.search-text-field-3:focus {
		width: 141px;
	}

	.weather-wrap-2 {
		padding-bottom: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.temperature-2 {
		margin-top: 5px;
		margin-bottom: 0px;
		font-size: 0.85rem;
		line-height: 0.95rem;
	}

	.mobile-search-button {
		position: relative;
		top: 2px;
		right: -6px;
		width: 100%;
		height: 36px;
		min-width: 40px;
	}

	.upper-divider {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.upper-divider.div-1 {
		margin-right: 0.25rem;
	}

	.upper-divider.div-2 {
		margin-left: 0.25rem;
	}

	.language-toggle {
		width: 62px;
	}

	.search-button-3 {
		position: relative;
		top: 2px;
		right: -6px;
		width: 40px;
		height: 36px;
	}

	.alert-close-2 {
		background-size: 24px 24px;
	}

	.alert-heading-2 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.alert-wrap {
		padding-top: 2.75rem;
		padding-right: 2rem;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.inside-content-sect {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 175px;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.logo-home-link {
		width: 110px;
		margin-left: 1.5rem;
	}
}

/* FONT SMOOTHING */

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

/* CONTROL PHONE NUMBER COLOR */

body a[href^=tel] {
	color: #000000;
	font-weight: normal;
}

/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */

/* Mozilla-based browsers */

/* CHANGE THE STYLE OF THE SLIDER PAGINATION */

.w-slider-nav > div {
	background-color: rgba(0, 0, 0, 0.22);
	cursor: pointer;
}

.w-slider-nav > div.w-active {
	background-color: #4578bc;
	cursor: pointer;
}

/* SWITCH OUT FLIGHT SEARCH WIDGETS BASED ON DESKTOP BROWSER WIDTH */

@media screen and (min-width: 992px) and (max-width: 1100px) {
	.find-flight-tool {
		display: none;
	}

	.mini-find-flight-widget {
		display: block;
	}
}

/* SHIFT POSITION OF FIND FLIGHT TOOL ON DESKTOP SCROLL */

@media screen and (min-width: 1350px) {
	.find-flight-tool {
		display: none;
	}

	.find-flight-tool.loaded {
		display: block;
	}

	.shift.find-flight-tool {
		top: 68px;
		padding: 0px 24px;
		background-color: transparent;
		z-index: 10001;
		width: 472px;
	}

	.shift .find-flight-dd {
		top: 35px;
		left: -63px;
	}

	.shift .view-all-flights {
		margin-top: 1.25rem;
	}
}

/* MAIN NAV ADJUSTMENTS */

@media screen and (min-width: 991px) and (max-width: 1240px) {
	.header-sect {
		padding-right: 0.5rem;
		padding-left: 1.5rem;
	}

	.logo-wrapper {
		width: 18.5%;
	}

	.logo-home-link {
		width: 136px;
		margin-right: 1.5rem;
	}
}

@media screen and (min-width: 991px) and (max-width: 1100px) {
	.main-nav-dd-widget {
		font-size: 0.88rem;
	}

	.main-nav-text {
		padding: 0.4rem 0.4rem;
	}

	.flight-search-dd-toggle {
		/* I wonder which one this was for! */
		font-size: 0.8rem;
	}

	.category-nav-link-2 {
		margin-right: 0.5rem;
		padding: 12px 0.75rem;
		letter-spacing: 0rem;
	}

	.upper-divider {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.upper-divider.div-1 {
		margin-left: 0.125rem;
	}

	.upper-divider.div-3 {
		margin-right: 0.25rem;
	}
}

@media screen and (min-width: 991px) and (max-width: 1020px) {
	.main-nav-dd-widget {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}
}

/* PROMO SLIDESHOW ADJUSTMENTS TO PREVENT ARROWS OVERLAPPING TEXT */

@media screen and (min-width: 821px) and (max-width: 860px) {
	.promo-item-inner-text-wrap {
		width: 90%;
	}
}

@media screen and (min-width: 768px) and (max-width: 820px) {
	.promo-item-inner-text-wrap {
		width: 85%;
	}
}

/****************** Tablesaw ***********************/

/****************************************************/

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/

/****************************************************/

[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/

/*************************************************/

#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/

/*****************************************************/
#page {
	overflow: hidden;
}

.mobile-search-form.search-cover-form .search-button {
	font-size: 0px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk_white.svg');
	background-position: 50% 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
}

.mobile-search-form fieldset,
.find-flight-form fieldset,
.search-form fieldset {
	display: flex;
	width: 100%;
}

.mobile-search-form fieldset div,
.find-flight-form fieldset div,
.search-form fieldset div {
	display: flex;
	width: 100%;
}

.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	height: 0;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.375rem 1rem !important;
	box-shadow: 0 0 5px #000;
	z-index: 1;
}

.header-sect a,
.header-sect a:hover {
	border-bottom: 0px;
}

.alert-close-2,
.mobile-search-button,
.search-close {
	background-color: transparent;
	cursor: pointer;
}

.alert-wrap h1 {
	margin-top: 0rem;
	font-family: 'Encode Sans', sans-serif;
	color: #fff;
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 600;
	text-transform: none;
}

.alert-wrap p {
	margin-top: 16px;
	color: #fff;
	font-size: 1.1rem;
}

.alert-wrap .button {
	height: auto;
	margin-bottom: 0rem;
	padding-top: 0.6rem !important;
	padding-bottom: 0.6rem !important;
	font-size: 0.88rem !important;
	margin-top: 1rem;
}

.alert-wrap .button:hover {
	font-size: 0.88rem !important;
}

.alert-wrap td {
	padding-left: 10px;
	padding-right: 10px;
	vertical-align: top;
}

.alert-wrap td:first-child {
	padding-right: 20px;
	padding-left: 0px;
}

.alert-wrap td p:last-child {
	margin-bottom: 0px;
}

.find-flight-dd-toggle {
	border: 0px;
	margin: 0px;
}

.search-form-block-2 .submit-button {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 35px;
	height: 38px;
	padding-right: 0px;
	border-radius: 4px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search_blk.svg');
	background-position: 5px 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	opacity: 0.6;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	display: block;
	text-indent: -9999px;
	background-color: transparent;
}

/* flight results */

.flight-search-dd-toggle-3 {
	display: none;
}

/* flight links */

.find-flight-tool:hover .flight-search-dd-widget-2 .find-flight-dd {
	-webkit-transition: opacity 500ms ease;
	transition: opacity 500ms ease;
}

.find-flight-tool .flight-search-dd-widget-2 .find-flight-dd .find-flight-outer-wrap {
	height: 0px;
}

.find-flight-tool:hover .flight-search-dd-widget-2 .find-flight-dd,
.find-flight-tool.keypress:hover .flight-search-dd-widget-2 .find-flight-dd,
.find-flight-tool.show-results .flight-search-dd-widget-2 .find-flight-dd {
	opacity: 1;
	height: auto;
}

.find-flight-tool:hover .flight-results-outer-wrap,
.find-flight-tool.show-results .flight-results-outer-wrap,
.find-flight-tool:hover .find-flight-search-results-wrap,
.find-flight-tool.show-results .find-flight-search-results-wrap,
.find-flight-tool:hover .flight-search-dd-widget-2 .find-flight-dd .find-flight-outer-wrap,
.find-flight-tool.show-results .flight-search-dd-widget-2 .find-flight-dd .find-flight-outer-wrap,
.find-flight-tool:hover .find-flight-view-all-wrap,
.find-flight-tool.show-results .find-flight-view-all-wrap,
.find-flight-tool:hover .view-all-inner-wrap,
.find-flight-tool.show-results .view-all-inner-wrap {
	height: auto;
}

.find-flight-tool .find-flight-search-results-wrap .flight-row:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	margin-top: 1.5rem;
}

.find-flight-tool .find-flight-search-results-wrap .flight-row:last-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.find-flight-tool:hover .flight-search-dd-widget-2 .find-flight-dd .find-flight-outer-wrap,
.find-flight-tool.show-results .flight-search-dd-widget-2 .find-flight-dd .find-flight-outer-wrap {
	display: flex;
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
	.flight-search-dd-toggle-3 {
		display: block;
		margin-top: 4px;
	}

	.find-flight-dd {
		top: 52px;
		left: 0px;
	}

	.find-flight-dd-toggle {
		display: none;
	}

	.find-flight-tool {
		padding-bottom: 0px;
		padding-top: 0px;
		right: 8px;
		padding-left: 15px;
		top: 103px;
		padding-right: 15px;
		background: #fff;
		opacity: 0;
		display: block;
		height: 0;
		z-index: 5000;
		width: 535px;
	}

	.find-flight-tool.show-results {
		height: auto;
		padding-bottom: 5px;
		padding-top: 12px;
		-webkit-transition: opacity 500ms ease;
		transition: opacity 500ms ease;
		opacity: 1;
	}
}

@media (max-width: 991px) {
	.alert-wrap h1 {
		margin-bottom: 0.5rem;
		font-size: 1.75rem;
		line-height: 2.75rem;
	}

	.alert-wrap p {
		margin-top: 0px;
	}

	.find-flight-form-block {
		float: none;
		max-width: 472px;
		margin: 0 auto;
	}

	.alert-wrap td {
		display: inline-block;
		width: 100% !important;
		text-align: left;
		padding-left: 0px;
		padding-right: 10px;
	}

	.find-flight-tool {
		display: block !important;
	}

	.exchange-dd-field {
		margin-bottom: 5px !important;
	}
}

@media (max-width: 767px) {
	.alert-wrap p {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.find-flight-form-block {
		max-width: 433px;
	}
}

@media (max-width: 479px) {
	.find-flight-dd-toggle {
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/flights.svg');
		background-repeat: no-repeat;
		text-indent: 9999px;
		background-size: 20px 20px;
		background-position: center;
	}

	.find-flight-form-block {
		max-width: 272px;
	}
}

/****************** Mobile Menu **********************/

/*****************************************************/

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #f9f9f9;
}

.mobile-navigation-menu li a:hover {
	background: rgba(69, 120, 188, 0.35);
}

.mobile-navigation-menu li a.nav-a {
	padding: 0.75rem 1.25rem;
	margin-left: 10px;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
}

.mobile-navigation-menu li.active a.nav-a {
	background-color: rgba(69, 120, 188, 0.35);
	box-shadow: inset 4px 0 0 0 #4578bc;
	color: #000;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview {
	border-color: rgba(0, 0, 0, 0.2);
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #000000;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
}

.mm-listitem:after {
	left: 10px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 70px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 70px;
}

.mm-btn_next:after {
	right: 33px;
}

.mm-navbar {
	border-color: rgba(0, 0, 0, 0.2);
	margin-left: 10px;
	text-align: left;
	height: 43px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar a:hover,
.mm-menu .mm-navbar > * {
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	border-bottom: 0px;
}

.mm-btn_prev:before {
	left: 18px;
	top: 3px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #000000;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -17px;
}

.mm-navbar__title {
	padding-top: 12px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.mm-wrapper_opened .alert-and-header-wrap {
		position: static;
	}
}

/****************** Accesible Menu *******************/

/*****************************************************/

ul.subnav-wrap {
	margin: 0px;
	padding: 0px;
	display: block;
	list-style-type: none;
}

ul.subnav-wrap li.main-nav-dd-widget {
	display: inline-block;
	position: relative;
	text-align: left;
	padding: 0px;
	margin-bottom: 0px;
	margin-top: 0px;
}

ul.subnav-wrap li.main-nav-dd-widget a {
	padding: 0.7rem 0.3rem 0.5rem 0.3rem;
}

ul.subnav-wrap li.main-nav-dd-widget a:hover,
ul.subnav-wrap li.main-nav-dd-widget a.active,
ul.subnav-wrap li.main-nav-dd-widget:hover a {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper {
	position: absolute;
	top: 100%;
	left: 0;
	padding-right: 1.1rem;
	padding-bottom: 0rem;
	padding-left: 1.1rem;
	background-color: #fff;
	width: auto;
	min-width: 150px;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
	height: 0;
	max-height: 0;
	opacity: 0;
	transition: max-height 1000ms ease 0s, opacity 1000ms ease 0s;
}

ul.subnav-wrap li.main-nav-dd-widget:hover div.container-wrapper,
ul.subnav-wrap li.main-nav-dd-widget.open div.container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul.sub-menu-list {
	display: none;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'] div.container-wrapper ul {
	display: none;
}

ul.subnav-wrap li.main-nav-dd-widget.open div.container-wrapper ul.sub-menu-list,
ul.subnav-wrap li.main-nav-dd-widget:hover div.container-wrapper ul.sub-menu-list {
	display: flex;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'].open div.container-wrapper ul,
ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false']:hover div.container-wrapper ul {
	display: block;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: flex;
	flex-wrap: nowrap;
	margin-right: 0.5rem;
	padding-top: 10px;
	padding-bottom: 10px;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul li {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 6px 0px 6px 0px;
	color: #034a94;
	font-size: 0.85rem;
	font-weight: 600;
	text-transform: uppercase;
	flex-grow: 1;
	white-space: nowrap;
	margin-right: 0rem;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul li a {
	color: #034a94;
	font-size: 0.85rem;
	font-weight: 600;
	text-transform: uppercase;
	box-shadow: none;
	text-decoration: none;
	border-bottom: 0px;
	padding: 0px 0px 0px 10px;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul ul {
	display: block;
	padding-top: 7px;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul ul li {
	float: none;
	white-space: normal;
	display: block;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 0px 2px 0px 0px;
	font-size: 0.85rem;
	font-weight: 500;
	margin-right: 0px;
	min-width: 190px;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul ul li a {
	font-size: 0.85rem;
	font-weight: 500;
	text-transform: none;
	color: #222222;
	padding-left: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
	display: inline-block;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul ul li a:hover,
ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper ul ul li a.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'] div.container-wrapper {
	padding-bottom: 0.2rem;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'] div.container-wrapper ul li {
	padding-top: 0px;
	padding-bottom: 0px;
	width: 100%;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'] div.container-wrapper ul li a {
	font-size: 0.85rem;
	font-weight: 500;
	text-transform: none;
	color: #222222;
	padding-left: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
	display: inline-block;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='true'] div.container-wrapper ul li a {
	cursor: default;
	pointer-events: none;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='true'] div.container-wrapper ul li li a {
	cursor: pointer;
	pointer-events: auto;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'] div.container-wrapper ul li a:hover,
ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='false'] div.container-wrapper ul li a.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

ul.subnav-wrap li.main-nav-dd-widget div.container-wrapper {
	padding-left: 5px;
	padding-right: 5px;
}

ul.subnav-wrap li.main-nav-dd-widget[data-megamenu='true'] div.container-wrapper ul ul li a {
	cursor: pointer;
}

@media (max-width: 1350px) {
	.accesible-navigation-menu.corporate ul.subnav-wrap li.main-nav-dd-widget:last-child .container-wrapper {
		left: -200px;
	}
}


/******************** Homepage **********************/

/*****************************************************/

.cb-feature-heading a,
.cb-feature-heading.reversed a,
.cb-feature-heading a:hover,
.cb-feature-heading.reversed a:hover,
.cb-header-wrap .cb-feature-heading a,
.cb-header-wrap .cb-feature-heading a:hover {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	border-bottom: 0px;
	text-decoration: none;
	box-shadow: none;
}

.feature-cb-paragraph p,
.feature-cb-paragraph p,
.feature-cb-paragraph.white-feature-cb-para p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.cb-header-wrap a {
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0px;
}

.cb-header-wrap a:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.home-content-section.hcs-row-4.hcs-row-4-corp {
		background-attachment: scroll;
		background-position: 50% 50%;
	}

	.home-content-section.hcs-row-4 {
		background-attachment: scroll;
	}
}

@supports (-ms-ime-align: auto) {
	.home-content-section.hcs-row-4.hcs-row-4-corp {
		background-attachment: scroll;
		background-position: 50% 50%;
	}

	.home-content-section.hcs-row-4 {
		background-attachment: scroll;
	}
}

@media (max-width: 991px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.find-flight-tool {
		top: 116px;
	}
}

@media (max-width: 767px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.find-flight-tool {
		top: 148px;
	}
}

@media (max-width: 479px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.find-flight-tool {
		top: 132px;
	}
}

/******************** Slideshow **************************/

/**********************************************************/

.slideshow-caption p:last-child {
	margin-bottom: 0px;
}

.button.slideshow-button:hover {
	display: inline-block;
	height: auto;
	margin-top: 0.75rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	float: left;
	clear: left;
	font-size: 0.85em;
	line-height: 1.5rem;
}

.promo-slide-item-wrapper {
	background-color: #f4f5f0;
	width: 100%;
	height: 100%;
}

.slideshow-caption {
	display: block;
	clear: left;
}

.slideshow-header {
	display: inline-block;
}

.slideshow-header,
.slideshow-caption,
.button.slideshow-button {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
	transition: none;
}

.cycle-slide-active .slideshow-header {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 900ms ease 0s, transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.cycle-slide-active .slideshow-caption {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 900ms ease 0s, transform 1100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.cycle-slide-active .button.slideshow-button {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 900ms ease 0s, transform 1100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, background-color 200ms ease-out;
}

.promo-slide.w-slide {
	background-color: #edeeea;
	height: 100%;
}

.corp-page-wrapper .hero-rotator {
	background-color: #005f9b;
	position: relative;
}

.corp-page-wrapper .slideshow-header {
	border-left: 4px solid #033466;
}

.corp-page-wrapper .button.slideshow-button {
	background-color: #033466;
	height: auto;
}

.corp-page-wrapper .button.slideshow-button:hover {
	background-color: #2a5ea3;
	cursor: pointer;
}

.corp-page-wrapper .slideshow-arrow.right-slide-arrow,
.corp-page-wrapper .slideshow-arrow.left-slide-arrow {
	background-color: #033466;
	cursor: pointer;
}

.header-sect.corp-homepage {
	border-bottom: 4px solid #033466;
}

.corp-page-wrapper .w-slider-nav > div.w-active {
	background-color: #033466;
	cursor: pointer;
}

@media (max-width: 991px) {
	.w-slide,
	.promo-link-wrapper {
		height: auto;
	}
}

/****************** Inside Page *******************/

/*************************************************/

.main-content-inner-wrap {
	min-height: 500px;
}

.left-nav-ul li a.active {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.left-nav-ul li li a.active {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child a:hover {
	color: #424242;
	border-bottom: 0px;
}

a.breadcrumb-link {
	font-weight: normal;
}

.left-nav-wrapper a,
.left-nav-wrapper a:hover {
	border-bottom: 0px;
}

/* td img {
	max-width: none;
} */

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/******************* Right Sidebar *******************/

/*****************************************************/

.right-side-col .box,
.left-nav-col .box {
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-top: 1.25rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col .box *,
.left-nav-col .box * {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box h4,
.right-side-col .box h3,
.left-nav-col .box h4,
.left-nav-col .box h3 {
	margin-top: 0px;
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-family: Raleway, sans-serif;
	font-weight: 600;
}

.right-side-col .box p,
.left-nav-col .box p {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col .box ul,
.left-nav-col .box ul {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box td,
.left-nav-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col .box .primary,
.right-side-col .box .secondary {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

/*********************** Footer **********************/

/*****************************************************/

.footer-section-wrap a,
.footer-section-wrap a:hover {
	border-bottom: 0px;
}

.phone-link.mobile {
	display: none;
}

.phone-link.desktop {
	display: inline;
}

#GRIPFooterLogo {
	opacity: 0.75;
	line-height: 11px;
	float: right;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-top: 0px !important;
	transition: all 200ms ease;
}

#GRIPFooterLogo:hover {
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.at-expanding-share-button a,
.at-expanding-share-button a:hover {
	border-bottom: 0px;
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline;
	}

	.phone-link.desktop {
		display: none;
	}

	#GRIPFooterLogo {
		float: none;
		margin-right: auto;
		margin-left: auto;
	}
}

/****************** Inside Page Styles ***************/

/*****************************************************/

body a {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

body a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	text-decoration: none;
	cursor: pointer;
	font-weight: inherit;
	font-size: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	color: #7c8186;
}

/* buttons */

.inside-body-section .button,
.primary,
.inside-body-section button[type="submit"],
.inside-body-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit],
.button {
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 0px;
	text-align: center;
	margin-top: 1rem;
	margin-right: 0.5rem;
	margin-bottom: 1rem;
}

.primary:hover,
.inside-body-section button[type="submit"]:hover,
.inside-body-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover,
.button:hover {
	background-color: #2a5ea3;
	color: #fff;
	padding: 0.8rem 1rem;
}

.secondary,
.inside-body-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary,
.inside-body-section .button.secondary {
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	border-radius: 4px;
	background-color: #d6dce0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 0px;
	text-align: center;
	margin-top: 1rem;
	margin-right: 0.5rem;
	margin-bottom: 1rem;
}

.secondary:hover,
.inside-body-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover,
.inside-body-section .button.secondary:hover {
	border-color: #221f1f;
	background-color: #c4ccd1;
	background-image: none;
	opacity: 1;
}

.tertiary,
.inside-body-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.25rem 0.75rem;
	-webkit-box-pack: center;
	border-radius: 4px;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #585b5d;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 2px solid #c4ccd1;
	text-align: center;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
}

.tertiary:hover,
.inside-body-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #c4ccd1;
	color: #000;
	padding: 0.25rem 0.75rem;
}

/* messages */

#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

#message.success *,
#message.error * {
	font-weight: 500;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */

table.styled {
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #2e2e2e;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #033466;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #034a94;
	color: #ffffff;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
	transition: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

table.styled.tablesaw tr {
	border-bottom: 0px;
}

table.styled.tablesaw tr td {
	border-left: 0px;
	border-bottom: 0px;
}

@media screen and (max-width: 767px) {
	table.styled.responsive td {
		border-right: none;
	}
}



/* forms */

.inside-page-wrapper input[type="text"],
.inside-page-wrapper input[type="tel"],
.inside-page-wrapper input[type="password"],
.inside-page-wrapper input[type="email"],
.inside-page-wrapper input[type="search"],
.inside-page-wrapper input[type="file"],
.inside-page-wrapper select,
.inside-page-wrapper textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	min-width: 0;
}

.inside-page-wrapper textarea {
	resize: vertical;
}

.inside-page-wrapper label {
	font-weight: 500;
	display: inline;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
}

.cms_label {
	font-weight: 500;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
}

@media (max-width: 991px) {
	.inside-page-wrapper input[type="text"],
	.inside-page-wrapper input[type="tel"],
	.inside-page-wrapper input[type="password"],
	.inside-page-wrapper input[type="email"],
	.inside-page-wrapper input[type="search"],
	.inside-page-wrapper input[type="file"],
	.inside-page-wrapper select,
	.inside-page-wrapper textarea {
		width: 100%;
	}
}

/*blockquotes*/

blockquote p {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

/*pagination*/

.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #6b6b6b;
}

/* date picker */

#ui-datepicker-div a {
	border-bottom: 0px;
}

/******************** Modules **********************/

/***************************************************/

/* accounts */

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #e9ece3;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #4578bc;
	font-size: 1.1rem;
	line-height: 1.3em;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

#account_module.entity.dashboard ul li {
	margin-bottom: 7px;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* search */

.module-search .collection > .node {
	border-top: #cccccc solid 1px;
	margin: 1.3rem 0 0 0;
	padding: 1.3rem 0 0 0;
}

.module-search .collection > .node:first-child {
	border-top: 0px;
	margin-top: 0px;
}

@media (max-width: 991px) {
	.module-search.controller-pages-search .main-content button {
		margin-top: 10px;
	}
}

/* events */

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_date.cms_date_with_time_comments {
	margin-bottom: 8px;
}

#events_module.cms_list .cms_date {
	font-size: 1rem;
	font-weight: 500;
}

#events_module.cms_list .location-label-events-module,
#events_module.cms_list .list-view-bottom-padding {
	font-weight: 500;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form .cms_td_answers label {
	font-weight: normal;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

#events_module.cms_list .cms_divider {
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
	border-color: rgba(0, 0, 0, 0.15);
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */

.module-files .collection--files a,
.module-files .collection--files a:hover,
.module-files .collection--categories a,
.module-files .collection--categories a:hover {
	border-bottom: 0px;
}

.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.3rem 0px 0px 0px;
	padding: 1.3rem 0px 0px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.module-files .field--category--title {
	background-position: 0 4px;
}

/* sidebar file filtering */

.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #cccccc;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

/* faq */

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
	border-color: rgba(0, 0, 0, 0.15);
}

#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

/* jobs */

#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.9rem;
	margin-bottom: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
	border-color: rgba(0, 0, 0, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: 500;
}

#jobs_module.cms_list .cms_list_item:last-child .cms_footer {
	display: none;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
	display: none;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.8rem 1rem;
	margin-top: 10px;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */

#locations_module.list .location_types .image {
	height: auto;
	width: auto;
}

#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(0, 0, 0, 0.15);
}

#locations_module .location-extra-fields .extra-field .extra-field-label,
#locations_module.list .location-phone-row .location-item-phone-label strong,
#locations_module.list .location-item-website .location-item-website-label strong {
	font-weight: 500;
}

#locations_module .cms_divider {
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

/* news */

#news_module.cms_list .cms_categories_row .image,
#news_module.cms_entity .cms_content .image {
	height: auto;
	width: auto;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.3rem;
	margin-bottom: 0.6rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list article time {
	font-weight: 500;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* mailing lists */

#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 12px 0 20px;
}

#mailinglists-module .moduleform .form_item .field label {
	font-weight: normal;
}

/* newslettter */

#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4,
#newsletters_module.list h3,
#newsletters_module.list h4 {
	margin-top: 0px;
}

#newsletters_module.list .item_divider {
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.form .form_item .field label {
	font-weight: normal;
}

#newsletters_module.form .instructions {
	font-weight: 500;
}

#newsletters_module.form .form_item .label {
	font-weight: 500;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* partners */

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
	height: auto;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_entity .image {
	height: auto;
	width: auto;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */

#photoalbums_module.cms_entity #carousel-slideshow {
	position: relative;
	overflow: hidden;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */

.right-side-col .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* staff */

#staffdirectory_module.cms_list .cms_list_item .cms_title {
	font-weight: 500;
}

#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
	height: auto;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#staffdirectory_module.cms_entity .image {
	height: auto;
	width: auto;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submisssion forms */

#submissionforms_module.cms_form .cms_field label {
	font-weight: normal;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	font-weight: 500;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
}

/* videos */

#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	padding-bottom: 1.3rem;
	margin-bottom: 1.3rem;
}

/* posts */

.posts-collection .posts-node {
	border-top: rgba(0, 0, 0, 0.15) solid 1px;
	margin: 1.3rem 0 0 0;
	padding: 1.3rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #034a94;
	font-weight: 500;
	margin-top: 25px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	font-weight: 500;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #e9ece3;
}

.posts-node .posts-comments #post-add-comment {
	margin-bottom: 20px;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"],
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-file #media {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-node .posts-comments #post-add-comment .required_information {
	color: #ee0000;
	font-family: inherit;
}

.posts-node .posts-comments #post-add-comment .ui-form-field.required .ui-form-label em {
	color: #ee0000;
	font-family: inherit;
}

/* sidebar posts */

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 10px 12px 8px !important;
}

.right-side-col .box.posts-blocks.posts-blocks-rss-feeds a,
.right-side-col .box.posts-blocks.posts-blocks-subscribes a,
.right-side-col .box.posts-blocks.posts-blocks-tags a {
	border-bottom: 0px;
}

/* gdpr */

#cookie-consent-form-container {
	max-height: 100vh;
}

#cookie-consent-form-container form .consent-header img {
	margin: 0 auto;
	display: block;
	float: none;
}

#cookie-consent-container.minimized {
	bottom: 10px;
	right: 10px;
}

#cookie-consent-container .consent-disclaimer a {
	border-bottom: 1px dotted #fff;
	color: #fff;
	text-decoration: none;
	font-weight: normal;
}

@media (max-width: 767px) {
	#cookie-consent-container {
		font-size: 0.8rem;
		line-height: 1.1rem;
	}
}

/***************** Content Boxes *******************/

/***************************************************/

/* events */

.content-box.callout-cb #events_module.homepage_contentbox a {
	color: #fff;
}

#events_module.homepage_contentbox {
	padding: 20px;
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
}

#events_module.homepage_contentbox .event_list {
	padding-bottom: 10px;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 80px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 80px);
}

/* files */

.page-wrapper .content-box.callout-cb .collection.files-collection a {
	color: #fff;
}

.page-wrapper .collection.files-collection {
	padding: 20px;
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
}

/* news */

.content-box.callout-cb #news_module.homepage_contentbox a,
.content-box.callout-cb #news_module.homepage_contentbox h3 {
	color: #fff;
}

#news_module.homepage_contentbox {
	padding: 20px;
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 10px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1rem;
	line-height: 1.3rem;
	margin: 0px;
	font-weight: normal;
}

#news_module.homepage_contentbox .cms_title h3 {
	font-size: 1.1rem;
	line-height: 1.5rem;
	margin: 0px 0px 8px 0px;
}

/* partners */

#partners_module.homepage_contentbox {
	padding: 20px;
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
}

.content-box.callout-cb #partners_module.homepage_contentbox a,
.content-box.callout-cb #partners_module.homepage_contentbox.displaymode_list .cms_list_item .cms_metadata1.cms_title .cms_content {
	color: #fff;
	font-size: 1rem;
}

/* posts */

.page-wrapper .collection.posts-collection {
	padding: 20px;
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
}

.page-wrapper .collection.posts-collection p {
	margin-bottom: 5px;
}

.page-wrapper .content-box.callout-cb .collection.posts-collection a {
	color: #fff;
}

.page-wrapper .posts-collection.homepage-contentbox.article-items .article {
	width: 100% !important;
	padding: 5px;
}

.page-wrapper .posts-collection.homepage-contentbox.article-items .article-image {
	width: auto;
}

.page-wrapper .posts-collection.homepage-contentbox.article-items .article-image-link {
	border-bottom: 0px;
}

.page-wrapper .posts-homepage-block.button.primary {
	font-size: 1rem;
	margin-left: 20px;
}

/* videos */

.page-wrapper .homepage-featured-video {
	margin: 20px;
}

.page-wrapper .homepage-featured-video iframe {
	max-width: 100%;
}

.page-wrapper .content-box.callout-cb .homepage-featured-video a {
	color: #fff;
}

/* form */

#submissionforms_module.homepage_contentbox {
	margin: 20px;
	text-align: left;
}

#submissionforms_module.homepage_contentbox label {
	font-weight: normal;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

.page-wrapper .content-box.callout-cb #submissionforms_module.homepage_contentbox .cms_hint,
.page-wrapper .content-box.callout-cb #submissionforms_module.homepage_contentbox .cms_date_hint {
	color: #fff;
	font-family: inherit;
}

/* Reports */

.report-table.styled tr.totals-row td,
.report-table.styled tr td.totals-cell {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #034a94;
	color: #fff;
}

.passenger-traffic .filters {
	text-align: right;
}

.passenger-traffic .report {
	display: none;
	clear: both;
}

.passenger-traffic .report.show {
	display: block;
}


/************ Custom Landing Page ****************/
/*************************************************/
.grid-container .contentbox-container {
	grid-gap: 0;
}

.grid-container .contentbox-container .content-box {
	padding: 20px 30px;
	width: 100%;
	margin: 0px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	min-height: 200px;
}

.grid-container .contentbox-container .content-box.default {
	background-color: #f5f5f5;
}

.grid-container .contentbox-container .content-box.section-two,
.grid-container .contentbox-container .content-box.video {
	background-color: #ccc;
}

.grid-container .contentbox-container .content-box.video {
	padding: 0px;
}

.grid-container .contentbox-container .content-box.video .home-content-section {
	padding: 30px 30px;
}

.grid-container .contentbox-container .content-box.default .content-box-image-container {
	text-align: center;
	margin-bottom: 10px;
}

.grid-container .contentbox-container .content-box.default .content-box-image-container img {
	max-height: 250px;
	width: auto;
}

.grid-container .contentbox-container .content-box.section-two p,
.grid-container .contentbox-container .content-box.section-two li,
.grid-container .contentbox-container .content-box.section-two span,
.grid-container .contentbox-container .content-box.section-two td,
.grid-container .contentbox-container .content-box.section-two a,
.grid-container .contentbox-container .content-box.section-two a:hover,
.grid-container .contentbox-container .content-box.video p,
.grid-container .contentbox-container .content-box.video li,
.grid-container .contentbox-container .content-box.video span,
.grid-container .contentbox-container .content-box.video td,
.grid-container .contentbox-container .content-box.video a,
.grid-container .contentbox-container .content-box.video a:hover {
	color: #fff !important;
	font-size: 1rem;
	line-height: 1.6rem;
}

.grid-container .contentbox-container .content-box.section-two h1,
.grid-container .contentbox-container .content-box.section-two h2,
.grid-container .contentbox-container .content-box.section-two h3,
.grid-container .contentbox-container .content-box.section-two h4,
.grid-container .contentbox-container .content-box.section-two h5,
.grid-container .contentbox-container .content-box.section-two h6,
.grid-container .contentbox-container .content-box.video h1,
.grid-container .contentbox-container .content-box.video h2,
.grid-container .contentbox-container .content-box.video h3,
.grid-container .contentbox-container .content-box.video h4,
.grid-container .contentbox-container .content-box.video h5,
.grid-container .contentbox-container .content-box.video h6 {
	color: #fff;
}

.grid-container .contentbox-container .content-box.video a.lightbox-link {
	text-decoration: none;
	border-bottom: 0px;
}

.grid-container .contentbox-container .content-box.video .div-block-39 {
	width: 100%;
	height: 80px;
}

.grid-container .contentbox-container .content-box.video .div-block-39 {
	width: 70px;
	height: 70px;
	opacity: 0.75;
}

.grid-container .contentbox-container .content-box.slideshow {
	padding: 0px;
}

@media (min-width: 991px) {
	.grid-container .contentbox-container .content-box.slideshow .hero-rotator {
		height: 60vh;
	}
}

@media (max-width: 991px) {
	.grid-container .contentbox-container .content-box.center .feature-cb-paragraph {
		text-align: center;
	}

	.grid-container .contentbox-container .content-box.slideshow .slide {
		background-position: 0px 0px, 50% 50%;
	}
}

/* issuu embed */
.issuu-wrapper {
	overflow: hidden;
}

.issuu-wrapper iframe {
	aspect-ratio: 16 / 9;
	height: auto;
	max-width: 100%;
	min-height: 450px;
}

/* GDPR Message links */
.main-content a.consent-more-trigger {
	background: #eeeeee6b;
	width: 100%;
	display: block;
	padding: 1rem;
	border: 1px dashed;
	text-decoration: underline;
	margin-top: 2rem;
	margin-bottom: 2rem;
	text-align: center;
}

.main-content a.consent-more-trigger:hover {
	border: 1px dashed;
	text-decoration: none;
}

span.underline-text {
	text-decoration: underline;
}
