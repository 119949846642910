/* Use relative rather than absolute units in CSS property values */
/******************************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.typed {
	font: normal 1.875em/3.125rem "Journal", Georgia, Times, serif;
}

/* core/libraries/select2/assets/select2.css */
.select2-container .select2-choice abbr {
	font-size: 0.0625rem;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}


/* Ensure that foreground and background colors have enough contrast */
/*********************************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
p.error {
	background-color: #ee0000;
	color: #fff;
	font-family: inherit;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #f2f2f2;
	color: #616161;
	font-family: inherit;
}


/* Removing the underline from links makes it hard for color-blind users to see them. */
/*************************************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.sigNav a.current,
.sigNav a.current:link,
.sigNav a.current:visited {
	text-decoration: underline;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}

/* Ensure that all information conveyed with color is also available without color */
/**********************************************************************************/
/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	background-color: #fff;
	font-weight: bold;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	color: #666;
	font-weight: bold;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #e6e6e6;
	font-weight: bold;
}

.fc-unthemed .fc-list-empty {
	background-color: #eee;
	font-weight: bold;
}
